import React, {useState, useEffect} from 'react'
import {Shimmer} from 'react-shimmer'
import Snackbar from '@material-ui/core/Snackbar';
import {fetchStaff,deleteSingleStaff} from '../../api/staff';
import {useSelector} from 'react-redux';
import {useHistory,Link} from "react-router-dom";
import MaterialTable from 'material-table'
import { serverApiUrl } from '../..';

export default function StaffDetails(props) {
    const {match}= props;
    const {params}=match;
    const history = useHistory();
    const adminDetails = useSelector(state=>state.ins.details);
    const [StaffData, setStaffData] = useState([]);
    const [showShimmer, setShowShimmer] = useState(true)
    const[SnackBarMessage, setSnackBarMessage] = useState("")
    const[typeStatus,setTypeStatus] = useState("Staff Details")
    const[isSnackBarShow, setIsSnackBarShow] = useState(false)
    const [deleteLoading,setDeleteLoading] = useState(false);

    const typeStyle = {textAlign: "center"};

    const deleteSingleRow=(rowId,index) => {
        if(!deleteLoading)
        {
            deleteStudent(rowId,index);
        }
    }

    const fetchStaffCallback = (response) => {
        if (response.msg === "success") {
            setStaffData(response.data)
        }
        if(response.msg === "error" && response.error === "no data"){
            setStaffData([]);
        }
        setShowShimmer(false)
    }

    const fetchClearStudentCallback = (response) => {
        if (response.msg === "success") {
            let newData = [];
            response.data.map((item, index) => {
                newData.push({serial_no: index + 1, ...item});
            });
            setStaffData(newData)
            setShowShimmer(false)
            setDeleteLoading(false)
        }
        if(response.msg === "error" && response.error === "no data"){
            setStaffData([]);
        }
    }


    useEffect(() => {
        fetchStaff(fetchStaffCallback)
    },[adminDetails.s_no])

    const deleteAtIndexCallback = (response,index) => {
        if (response.msg === "success") { 
            let instituteData_local = [...StaffData]
            instituteData_local.splice(index, 1);
            setStaffData(instituteData_local) 
            setSnackBarMessage("Student Deleted Successfully")
            setIsSnackBarShow(true)
            history.push("/staffDetails");
        }
        else {
            setSnackBarMessage("Something went wrong")
            setIsSnackBarShow(true)
        }
    }

    const deleteStudent =(student_id,index)=>{ 
        deleteSingleStaff(student_id,(response)=>deleteAtIndexCallback(response,index));
    }

    const closeSnack=()=>{
        setIsSnackBarShow(false)
    }

    const columns = [
        {title:'Staff Id',field:'s_no',type:'numeric'},
        {title:'Name',field:'user_name'},
        {title:'Email',field:'name'},
        {title:'Joining Date',field:'created_date',type:'date',dateSetting:{locale:'en-GB'}},
    ]

    columns.push(
        {
            title:"Action",
            render:(row) =>
            <>
                <button className="btn btn-danger m-1" onClick={()=> deleteSingleRow(row.s_no,row.serial_no)}>
                    {deleteLoading?(
                        <div className="spinner-border text-light" role="status">   <span className="sr-only">Loading...</span>
                        </div>
                    ):(
                        <span>Delete</span>
                    )}
                </button>
                <Link to={"/staff/"+row.s_no}>
                    <button className="btn btn-info m-1"  data-toggle="modal" data-target={"#exampleModalCentered"+row.serial_no}>
                        Edit
                    </button>
                </Link>
            </>
        },
    );


    return (
        <>

            <div className="page-breadcrumb d-none d-md-flex align-items-center mb-3">
                <div className="ml-auto">
                    <Link to={"/staff"}>
                        <button className="btn btn-primary">Add</button>
                    </Link>
                </div>
            </div>

            <div className="card mt-20">
                <div className="card-body">
                    <div className="table-responsive">
                        <MaterialTable
                            title={typeStatus}
                            data = {StaffData}
                            columns = {columns}
                            options={{
                                exportButton: true,
                                filtering: true,
                                search: false,
                                pageSize: 10,
                                pageSizeOptions:[10, 20, 50],
                                draggable:false,
                                paginationType: "stepped" 
                            }}
                            localization={{
                                body: {
                                    emptyDataSourceMessage: 'No records to display',
                                }
                            }}
                        />
                    </div>
                </div>
            </div>

            <Snackbar
                open={isSnackBarShow}
                onClose={(e)=>closeSnack(e)}
                TransitionComponent="TransitionUp"
                message={SnackBarMessage}
            />
        </>
    )
}
