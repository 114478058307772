import React, { useState, useEffect, useRef } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import { useSelector } from 'react-redux';
import { fetchOfficeDetails,editOfficeDetails} from '../../api/office';
import { serverBaseUrl } from '../..';

export default function OfficeDetails(props) {
    const adminDetails = useSelector(state=>state.ins.details);
    const [officeName, setOfficeName] = useState("") 
    const [officeMobile, setOfficeMobile] = useState("")
    const [officeAddress, setOfficeAddress] = useState("")
    const [profileImg,setProfileImg] = useState("")
    const [aadharImg,setAadharImg] = useState("")
    const [logoImgDisplay,setLogoImgDisplay] = useState("")
    const [sign_stampImgDisplay,setAadharImgDisplay] = useState("")
    const[SnackBarMessage, setSnackBarMessage] = useState("")
    const[isSnackBarShow, setIsSnackBarShow] = useState(false)
    const[addEditLoading,setAddEditLoading] = useState(false)
    
    const mylabel = {color: "#5c00e6"};
    const myIdLabel = {color: "#5c00e6"};
    const border = {border: "2px solid #d1b3ff",padding: "30px",borderRadius:"10px"};
    const profileimg = {height: "100px",width: "auto"}
    const aadharimg = {height: "100px",width: "auto"}
    let officeLogoRef = useRef()
    let idCardRef = useRef()
  

    const logoImageHandler = (e)=>{
        if(e.target.files[0])
        {
            setLogoImgDisplay(URL.createObjectURL(e.target.files[0]))
            setProfileImg(e.target.files[0]);
        }
    }

    const signStampImgHandler = (e)=>{
        if(e.target.files[0])
        {
            setAadharImgDisplay(URL.createObjectURL(e.target.files[0]))
            setAadharImg(e.target.files[0]);
        }
    }


    const fetchOfficeDetailsCallback=(response)=>{
        if(response.msg==="success"){
            setOfficeName(response.data.lib_name);
            setOfficeMobile(response.data.office_no);
            setOfficeAddress(response.data.office_address);
            // setProfileImg(response.data.logo);
            setLogoImgDisplay(serverBaseUrl+response.data.logo);
            setAadharImgDisplay(serverBaseUrl+response.data.sign_stamp);
        }
    }

    useEffect(()=>{
        fetchOfficeDetails(adminDetails.s_no,fetchOfficeDetailsCallback)
    },[adminDetails.s_no])

    const editOfficeDetailsCallback = (response) => {
        if (response.msg === "success") {
            setSnackBarMessage("Student Edited Successfully")
            setIsSnackBarShow(true)
        }else{
            setSnackBarMessage("Something went wrong")
            setIsSnackBarShow(true)
        }
        setAddEditLoading(false)
    }

    const editOfficeDetailsHandler = ()=>{
            if(!addEditLoading)
            {
                setAddEditLoading(true)
                // editStudent(params.id,adminDetails.s_no,officeName,officeMobile,officeAddress,presentAddress,aadharNo,mobileNo,startTime,endTime,profileImg,aadharImg,examType,otherTypeExam,seatNo,editstudentCallback)
                editOfficeDetails(adminDetails.s_no,officeName,officeMobile,profileImg,aadharImg,officeAddress,editOfficeDetailsCallback);
            }
    }

    const closeSnack=()=>{
        setIsSnackBarShow(false)
    } 

    return(

        <div className="wrapper container ">
            <div className="page-breadcrumb d-none d-md-flex align-items-center mb-3">
                <div className="breadcrumb-title pr-3 border-0">Edit Office details</div>
            </div>

            <form>
                <div className="container mt-5" style={border}>
                    <b style={mylabel} className="ml-3"><label htmlFor="endTime">Office Logo: </label></b>
                    <b style={myIdLabel} className="ml-3 float-right"><label htmlFor="endTime">Id Card Stamp: </label></b>
                    <div className="row">
                        <div className="col-6">
                            <button className="float-left row" type="button" onClick={()=>{officeLogoRef.click()}} style={{backgroundColor:"transparent",border:"none"}}>
                                <img style={profileimg}  src={logoImgDisplay}  alt="logo"/>
                                <div className="d-flex justify-content-end align-items-end ml-1">
                                    <span className="btn btn-primary"><i className="bx bx-pencil"></i></span>   
                                </div>
                            </button>
                            <input type="file" ref={ref =>officeLogoRef=ref} id="my_file" style={{visibility: "hidden"}} onChange={(e)=>logoImageHandler(e)}/>
                        </div>

                        <div className="col-6">
                            <button className="float-right row " type="button" onClick={()=>{idCardRef.click()}} style={{backgroundColor:"transparent",border:"none"}}>
                                <img style={aadharimg}  src={sign_stampImgDisplay}  alt="stamp"/>
                                <div className="d-flex justify-content-end align-items-end ml-1">
                                    <span className="btn btn-primary"><i className="bx bx-pencil"></i></span>   
                                </div>
                            </button>
                            <input type="file" ref={ref =>idCardRef=ref} id="my_file" style={{visibility: "hidden"}} onChange={(e)=>signStampImgHandler(e)}/>
                        </div>
                    </div>

                
                    <div className="row mt-1" style={mylabel}>
                        <div className="col-6 form-floating mb-3 mt-2">
                            <b><label htmlFor="officeName">Office Name</label></b>
                            <input type="text" className="form-control" id="officeName" value={officeName} onChange={e => setOfficeName(e.target.value)} placeholder="Office Name" />
                        </div>
                        <div className="col-6 form-floating mb-3 mt-2">
                            <b><label htmlFor="officeName">Office Mobile No.</label></b>
                            <input type="text" className="form-control" id="officeName" value={officeMobile} onChange={e => setOfficeMobile(e.target.value)} placeholder="Mobile No" />
                        </div>
                    </div>

                    <div className="row" style={mylabel}>
                        <div className="col form-floating mb-3 mt-2">
                            <b><label htmlFor="officeAddress">Office Address</label></b>
                            <textarea className="form-control" placeholder="Office address" value={officeAddress} id="officeAddress" onChange={e => setOfficeAddress(e.target.value)}></textarea>
                        </div>
                    </div>

                    
                    <div className="btn-group mt-5 w-100">
                        <button type="button" onClick={editOfficeDetailsHandler} className="btn btn-primary btn-block" >
                            {addEditLoading?(
                                <div className="spinner-border text-light" role="status">	<span className="sr-only">Loading...</span>
                                </div>
                            ):(
                                <span>Save Details</span>
                            )}
                        </button>
                    </div>

                </div>
            </form>

            <Snackbar
                open={isSnackBarShow}
                onClose={(e)=>closeSnack(e)}
                TransitionComponent="TransitionUp"
                message={SnackBarMessage}
            />
        </div>
    )
}
