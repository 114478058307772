import React, { useState, useEffect } from 'react';
import {useSelector,useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom'
import { SET_ADMIN_DETAILS, SET_AUTH_STATUS,SET_ADMIN_ROLE} from '../../Reducers/types';
import { fetchOfficeDetails} from '../../api/office';
import { serverBaseUrl } from '../..';
import 'jquery/dist/jquery.min.js';
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";

export default function Header() {
    const adminDetails = useSelector(state=>state.ins.details);
    const [officeName, setOfficeName] = useState("");
    const [email, setEmail] = useState("");
    const [logoImgDisplay,setLogoImgDisplay] = useState("");
    const history = useHistory();
    const dispatch = useDispatch();
    const fetchOfficeDetailsCallback=(response)=>{
        if(response.msg==="success"){
            setOfficeName(response.data.lib_name);
            let logoUrl = response.data.logo;
            if (!logoUrl) {
                logoUrl = "assets/images/avatars/avatar-1.png";
            }
            setLogoImgDisplay(serverBaseUrl+logoUrl);
        }
    }

    const onLogoutClickhanlder = ()=> {
        dispatch({type:SET_AUTH_STATUS,payload:{authStatus:false}})
        dispatch({type:SET_ADMIN_DETAILS,payload:{details:[]}})
        dispatch({type:SET_ADMIN_ROLE,payload:{role:null}})
        history.push("/");
    }

    useEffect(()=>{
        fetchOfficeDetails(adminDetails.s_no,fetchOfficeDetailsCallback)
        setEmail(adminDetails.name)
    },[adminDetails.s_no,adminDetails.name])

    return(
        <>
            <header className="top-header">
              <nav className="navbar navbar-expand">
                <div className="left-topbar d-flex align-items-center">
                  <a href="javascript:void(0);" className="toggle-btn">
                    {" "}
                    <i className="bx bx-menu" />
                  </a>
                </div>
                <div className="right-topbar ml-auto">
                  <ul className="navbar-nav">
                    <li className="nav-item dropdown dropdown-user-profile">
                      <a
                        className="nav-link dropdown-toggle dropdown-toggle-nocaret"
                        href="javascript:void(0);"
                        data-toggle="dropdown"
                      >
                        <div className="media user-box align-items-center">
                          <div className="media-body user-info">
                            <p className="user-name mb-0">{officeName} </p>
                            <p className="designattion mb-0">{email}</p>
                          </div>
                          <img
                            src={logoImgDisplay}
                            className="user-img"
                            alt="user avatar"
                          />
                        </div>
                      </a>
                      <div className="dropdown-menu dropdown-menu-right">
                        {" "}
                        <div className="dropdown-divider mb-0" />{" "}
                        <a className="dropdown-item" href="javascript:void(0);" onClick={onLogoutClickhanlder}>
                          <i className="bx bx-power-off" />
                          <span>Logout</span>
                        </a>
                      </div>
                    </li>
                    <li className="nav-item dropdown dropdown-language">
                      <a
                        className="nav-link dropdown-toggle dropdown-toggle-nocaret"
                        href="javascript:void(0);"
                        data-toggle="dropdown"
                      >
                        <div className="lang d-flex">
                          <div>
                            <i className="flag-icon flag-icon-in" />
                          </div>
                          <div>
                            <span>IN</span>
                          </div>
                        </div>
                      </a>
                      {/* <div className="dropdown-menu dropdown-menu-right">
                        {" "}
                        <a className="dropdown-item" href="javascript:;">
                          <i className="flag-icon flag-icon-de" />
                          <span>German</span>
                        </a>
                        <a className="dropdown-item" href="javascript:;">
                          <i className="flag-icon flag-icon-fr" />
                          <span>French</span>
                        </a>
                        <a className="dropdown-item" href="javascript:;">
                          <i className="flag-icon flag-icon-um" />
                          <span>English</span>
                        </a>
                        <a className="dropdown-item" href="javascript:;">
                          <i className="flag-icon flag-icon-in" />
                          <span>Hindi</span>
                        </a>
                        <a className="dropdown-item" href="javascript:;">
                          <i className="flag-icon flag-icon-cn" />
                          <span>Chinese</span>
                        </a>
                        <a className="dropdown-item" href="javascript:;">
                          <i className="flag-icon flag-icon-ae" />
                          <span>Arabic</span>
                        </a>
                      </div> */}
                    </li>
                  </ul>
                </div>
              </nav>
            </header>
        </>
    )
}