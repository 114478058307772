import React, { useState, useEffect } from 'react'
import RenderSingleStudentFees from './RenderSingleStudentFees'
import { Shimmer } from 'react-shimmer'
import Snackbar from '@material-ui/core/Snackbar';
import {fetchfeesDetails,deleteSingleFeeRecipt,fetchSearchFeesDetails} from '../../api/fees';
import { useSelector } from 'react-redux';
import { useHistory,Link } from "react-router-dom";
import $ from 'jquery';
import MaterialTable from 'material-table'
import { serverApiUrl } from '../..';

export default function Fees(props) {
    const {match}= props;
    const {params}=match;
    const history = useHistory();

    const adminDetails = useSelector(state=>state.ins.details);
    const authStatus = useSelector((state)=>state.ins.role)
    const [status, setStatus] = useState("staff");

    useEffect(()=>{
        setStatus(authStatus)
    },[authStatus])

    const [feesData, setFeesData] = useState([]);
    const [showShimmer, setShowShimmer] = useState(true)
    const[SnackBarMessage, setSnackBarMessage] = useState("")
    const[isSnackBarShow, setIsSnackBarShow] = useState(false)
    const [searchValue,setSearchValue] = useState("")
    const [deleteLoading,setDeleteLoading] = useState(false);

    const searchBoxHandler = (value) => {
        setSearchValue(value);
        if (adminDetails.s_no) {
            if (value) {
                fetchSearchFeesDetails(value,adminDetails.s_no,fetchFeesDetailsCallback)
            } else {
                fetchfeesDetails(adminDetails.s_no,fetchFeesDetailsCallback)
            }
        }
    }

    const deleteSingleRow=(rowId,index) => {
        if(!deleteLoading)
        {
            setDeleteLoading(true)
            deleteFee(rowId,index);
            setDeleteLoading(false)
        }
    }

    const fetchFeesDetailsCallback = (response) => {
        if (response.msg === "success") {
            let newData = [];
            response.data.map((item, index) => {
                newData.push({serial_no: index + 1, ...item});
            });
            setFeesData(newData)
            setShowShimmer(false)
        }
    }


    useEffect(() => {
        if(params.id) {
            setSearchValue(params.id)
            fetchSearchFeesDetails(params.id,adminDetails.s_no,fetchFeesDetailsCallback)
        } else if (adminDetails.s_no) {
            fetchfeesDetails(adminDetails.s_no,fetchFeesDetailsCallback)
        }
    },[adminDetails.s_no,params.id])


    const deleteAtIndexCallback = (response,index) => {
        if (response.msg === "success") { 
            fetchfeesDetails(adminDetails.s_no,fetchFeesDetailsCallback)
            setSnackBarMessage("Fees Deleted Successfully")
            setIsSnackBarShow(true)
        }
        else {
            setSnackBarMessage("Something went wrong")
            setIsSnackBarShow(true)
        }
    }

    const deleteFee =(student_id,index)=>{
        deleteSingleFeeRecipt(student_id,(response)=>deleteAtIndexCallback(response,index));
    }

    const closeSnack=()=>{
        setIsSnackBarShow(false)
    }

    const columns = [
        {title:'Receipt Id#',field:'payment_receipt_no',type:'numeric'},
        {title:'Payment Receipt Date',field:'payment_receipt_date',type:'date',dateSetting:{locale:'en-GB'}},
        {title:'Name',field:'name'},
        {title:'Father Name',field:'f_name'},
        {title:'Studnet Id',field:'student_id',render:(row) => "MS-"+row.student_id,defaultFilter: searchValue,type:'numeric'},
        {title:'Payment Mode',field:'payment_mode'},
        {title:'Start Month',field:'from_month',type:'date',dateSetting:{locale:'en-GB'}},
        {title:"End Month",field:'to_month',type:'date',dateSetting:{locale:'en-GB'}},
        {title:"Register Fees",field:'register_fees',type:'currency',currencySetting:{ currencyCode:'INR'}},
        {title:"Locker Fees",field:'locker_fees',type:'currency',currencySetting:{ currencyCode:'INR'}},
        {title:"Library Fees",field:'lib_fees',type:'currency',currencySetting:{ currencyCode:'INR'}},
        {title:"Total Fees",field:'total_fees',type:'currency',currencySetting:{ currencyCode:'INR'}},
        {title:"Paid Fees",field:'paid_fees',type:'currency',currencySetting:{ currencyCode:'INR'}},
        {title:"Due Fees",field:'due_fees',type:'currency',currencySetting:{ currencyCode:'INR'}},
        {
            title:"Fee Recipt",
            field:'seat_number',
            export: false,
            filtering: false,
            render:(row) =>
                <>
                    <a className="btn btn-success m-1"  href={serverApiUrl+row.fee_recipt_location} rel="noreferrer" target="_blank">
                        View
                    </a>
                    {
                        (status != 'staff') ? (
                            <>
                                <button className="btn btn-danger m-1" onClick={()=> deleteSingleRow(row.id,row.serial_no)}>
                                        {deleteLoading?(
                                            <div className="spinner-border text-light" role="status">   <span className="sr-only">Loading...</span>
                                            </div>
                                        ):(
                                            <span>Delete</span>
                                        )}
                                </button>
                                <Link to={"/recipt/"+row.id}>
                                    <button className="btn btn-info m-1"  data-toggle="modal" data-target={"#exampleModalCentered"+row.serial_no}>
                                        Edit
                                    </button>
                                </Link>
                            </>
                        ) : ""
                    }
                </>
        },
    ]


    return (
        <>

            <div className="page-breadcrumb d-none d-md-flex align-items-center mb-3">
                <div className="ml-auto">
                    <Link to={"/recipt"}>
                        <button className="btn btn-primary">Add</button>
                    </Link>
                </div>
            </div>


            <div className="card">
                <div className="card-body">
                    <div className="table-responsive">
                        <MaterialTable
                            title="Fees Management"
                            data = {feesData}
                            columns = {columns}
                            options={{
                                exportButton: true,
                                filtering: true,
                                search: false,
                                pageSize: 10,
                                pageSizeOptions:[10, 20, 50, 100, 300],
                                draggable:false,
                                paginationType: "stepped" 
                            }}
                            localization={{
                                body: {
                                    emptyDataSourceMessage: 'No records to display',
                                }
                            }}
                        />
                    </div>
                </div>
            </div>

            <Snackbar
                open={isSnackBarShow}
                onClose={(e)=>closeSnack(e)}
                TransitionComponent="TransitionUp"
                message={SnackBarMessage}
            />
        </>
    )
}
