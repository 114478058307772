import {serverApiUrl} from '../';

export const fetchStaff = (callback) => 
{
    let formaData  = new FormData();
    formaData.append("fetch_staff",true);
    fetch(serverApiUrl+"staff_details.php",{
        method: 'POST',
        body:formaData
    }).then(response =>response.json())
    .then(response=>callback(response))
    .catch(errr=>console.log(errr))
}

export const deleteSingleStaff = (staffId,callback) => 
{
    let formaData  = new FormData();
    formaData.append("s_no",staffId);
    formaData.append("delete_staff",true);
    fetch(serverApiUrl+"staff_details.php",{
        method: 'POST',
        body:formaData
    }).then(response =>response.json())
    .then(response=>callback(response))
    .catch(errr=>console.log(errr))
}

export const fetchEditDetails = (staffId,callback) => 
{
    let formaData  = new FormData();
    formaData.append("s_no",staffId);
    formaData.append("fetch_staff",true);
    fetch(serverApiUrl+"staff_details.php",{
        method: 'POST',
        body:formaData
    }).then(response =>response.json())
    .then(response=>callback(response))
    .catch(errr=>console.log(errr))
}


export const addStaff = (name,email,password,callback) => 
{
    let formaData  = new FormData();
    formaData.append("name",name);
    formaData.append("email",email);
    formaData.append("password",password);
    formaData.append("insert_staff",true);
    fetch(serverApiUrl+"staff_details.php",{
        method: 'POST',
        body:formaData
    }).then(response =>response.json())
    .then(response=>callback(response))
    .catch(errr=>console.log(errr))
}


export const editStaff = (staffId,name,email,password,callback) => 
{
    let formaData  = new FormData();
    formaData.append("s_no",staffId);
    formaData.append("user_name",name);
    formaData.append("name",email);
    formaData.append("password",password);
    formaData.append("update_staff",true);
    fetch(serverApiUrl+"staff_details.php",{
        method: 'POST',
        body:formaData
    }).then(response =>response.json())
    .then(response=>callback(response))
    .catch(errr=>console.log(errr))
}