import React from 'react';
import Login from '../../components/Login/Login';
import { Route } from 'react-router-dom'  
import Dashboard from '../../Pages/Dashboard/Dashboard';
import Slot from '../../Pages/Slot/Slot';
import Seat from '../../Pages/Seat/Seat';
import Student from '../../Pages/Student/Student';
import StudentDetails from '../../Pages/Student/showDetails';
import OfficeDetails from '../../Pages/Office/OfficeDetails';
import Fees from '../../Pages/Fees/Fees';
import Recipt from '../../Pages/Fees/AddRecipt';
import ReciptNew from '../../Pages/Fees/NewRecipt';
import StaffDetails from '../../Pages/Staff/showDetails';
import Staff from '../../Pages/Staff/Staff';

import { Redirect } from "react-router-dom"
import { useSelector } from 'react-redux'

const routerSwitch = (authStatus)=>
{
  return(
      <>
        <Route exact path="/" component={Login} /> 
        <PrivateRoute exact path="/Slot" authStatus={authStatus} component={Slot} /> 
        <PrivateRoute exact path="/dashboard" authStatus={authStatus} component={Dashboard} />  
        <PrivateRoute exact path="/seat" authStatus={authStatus} component={Seat} />  
        <PrivateRoute exact path="/student" authStatus={authStatus} component={Student} /> 
        <PrivateRoute exact path="/student/:id" authStatus={authStatus} component={Student} />  
        <PrivateRoute exact path="/studentDetails" authStatus={authStatus} component={StudentDetails} />  
        <PrivateRoute exact path="/studentDetails/:id" authStatus={authStatus} component={StudentDetails} /> 
        <PrivateRoute exact path="/officeDetails" authStatus={authStatus} component={OfficeDetails} />  
        <PrivateRoute exact path="/fees" authStatus={authStatus} component={Fees} />  
        <PrivateRoute exact path="/fees/:id" authStatus={authStatus} component={Fees} /> 
        <PrivateRoute exact path="/recipt" authStatus={authStatus} component={Recipt} /> 
        <PrivateRoute exact path="/recipt/:id" authStatus={authStatus} component={Recipt} />
        <PrivateRoute exact path="/addRecipt/:id" authStatus={authStatus} component={ReciptNew} />
        <PrivateRoute exact path="/staff" authStatus={authStatus} component={Staff} />
        <PrivateRoute exact path="/staff/:id" authStatus={authStatus} component={Staff} />
        <PrivateRoute exact path="/staffDetails" authStatus={authStatus} component={StaffDetails} />
      </>
  )
}
 
const PrivateRoute = ({component: Component, authStatus,...rest}) => {
 
  return (
      // restricted = false meaning public route
      // restricted = true meaning restricted route
      <Route {...rest} render={props => (
        authStatus ?
            <Component {...props} />
        : <Redirect to="/" />
    )} />
  );
};
const Routes = props => {

  const authStatus = useSelector((state)=>state.ins.authStatus)
    return  (
        <div className="page-wrapper">
            <div className="page-content-wrapper">
                <div className="page-content">
                    {routerSwitch(authStatus)}
                </div>
            </div>
        </div> 
            
    );
};
 

export default  Routes ;