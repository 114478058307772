import React, { useState } from 'react';
import { useDispatch } from 'react-redux'
import { SET_ADMIN_DETAILS, SET_AUTH_STATUS ,SET_ADMIN_ROLE} from '../../Reducers/types';
import {useHistory} from 'react-router-dom'
import { login } from '../../api/auth';
import {deleteSeats} from '../../api/dashboard';
 
const Login= props => 
{
    
    const [name, setName]=useState("");
    const [password, setPassword]=useState("");
    const [error,setError] = useState(null);
    const [loading,setLoading] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory();
    const loginCallback=(response)=>
    {
        setLoading(false)
        if(response.msg==="success")
        {
            deleteSeats(deleteSeatsCallback);
            dispatch({type:SET_AUTH_STATUS,payload:{authStatus:true}})
            dispatch({type:SET_ADMIN_DETAILS,payload:{details:response.data}})
            dispatch({type:SET_ADMIN_ROLE,payload:{role:response.data.role}})
            history.push("/dashboard");
        }else
        {
            setError("Inavlid Credentails")
        }
    }
    const deleteSeatsCallback = (response) => {
    }
    const onLoginClickhanlder=()=>
    {
        if(!loading)
        {
            setLoading(true)
            login(name, password, loginCallback)
        }
        
    }

    return(
            <div className="wrapper">
                <div className="section-authentication-login d-flex align-items-center justify-content-center">
                    <div className="row">
                        <div className="col-12 col-lg-10 mx-auto">
                            <div className="card radius-15">
                                <div className="row no-gutters">
                                    <div className="col-lg-6">
                                        <div className="card-body p-md-5">
                                            <div className="text-center">
                                                <img src="assets/images/logo-icon.png" width="80%" alt=""/>
                                                <h3 className="mt-4 font-weight-bold">Welcome Back</h3>
                                            </div>
                                            <div className=" mt-5">
                                                {error?<span style={{fontWeight: "bold",color:"red"}}>{error}</span>:null}
                                            </div>
                                            <div className="form-group mt-4">
                                                <label>Email Address</label>
                                                <input type="text" className="form-control" placeholder="Enter your name" onChange={(e)=>setName(e.target.value)} />
                                            </div>
                                            <div className="form-group">
                                                <label>Password</label>
                                                <input type="password" className="form-control" placeholder="Enter your password" onChange={(e)=>setPassword(e.target.value)} />
                                            </div>
                                            <div className="btn-group mt-3 w-100">
                                                <button onClick={onLoginClickhanlder} type="button" className="btn btn-primary btn-block" >
                                                    {loading?(
                                                        <div className="spinner-border text-light" role="status">	<span className="sr-only">Loading...</span>
                                                        </div>
                                                    ):(
                                                        <span>Log In</span>
                                                    )}
                                                        
                                                
                                                </button>
                                                <button onClick={onLoginClickhanlder} type="button" className="btn btn-primary"><i className="lni lni-arrow-right"></i>
                                                </button>
                                            </div>
                                            <hr/>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <img src="assets/images/login-images/login-frent-img.jpg" className="card-img login-img h-100" alt="..."/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
      
    )
}
export default Login