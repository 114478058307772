import { Link } from "react-router-dom"
import {useSelector} from 'react-redux'
import React, { useState, useEffect } from 'react';

export default function LeftNav() {
    const authStatus = useSelector((state)=>state.ins.role)
    const [status, setStatus] = useState("staff");

    useEffect(()=>{
        setStatus(authStatus)
    },[authStatus])
    return(
        <>
            <div className="sidebar-wrapper" data-simplebar="true">
              <div className="sidebar-header">
                <div className>
                </div>
                <div>
                  <h6 className="logo-text">Library Admin</h6>
                </div>
                <a href="javascript:void(0);" className="toggle-btn ml-auto">
                  {" "}
                  <i className="bx bx-menu" />
                </a>
              </div>
              <ul className="metismenu" id="menu">

                <li>
                  <Link to={"/dashboard"}>
                    <div className="parent-icon icon-color-5">
                      <i className="bx bx-home-alt" />
                    </div>
                    <div className="menu-title">Dashboard</div>
                  </Link>
                </li>
                {
                    (
                        (status != 'staff') ?
                        (
                            <li>
                                <Link to={"/officeDetails"}>
                                    <div className="parent-icon icon-color-5">
                                        <i className="bx bx-group" />
                                    </div>
                                    <div className="menu-title">Office</div>
                                </Link>
                            </li>
                        ) : ""
                    )
                }

                {/* <li>
                  <Link to={"/studentDetails"}>
                    <div className="parent-icon icon-color-5">
                      <i className="bx bx-group" />
                    </div>
                    <div className="menu-title">Students</div>
                  </Link>
                </li> */}

                <li className="treeview">
                    
                    <a href="javascript:void(0);">
                      <div className="parent-icon icon-color-5">
                        <i className="bx bx-group" />
                      </div>
                      <span className="ml-2">Student Management</span>
                      <span className="pull-right-container">
                         <i className="fa fa-angle-left pull-right"></i>
                      </span>
                    </a>
                    
                    <ul className="treeview-menu">
                       <li className="ml-4">
                          <Link to={"/studentDetails/0"}>
                             <i className="bx bx-circle"></i>All students
                          </Link>
                       </li>
                       <li className="ml-4">
                          <Link to={"/studentDetails/2"}>
                             <i className="bx bx-circle"></i>Due Fees students
                          </Link>
                       </li>
                    </ul>
                 </li>

                {
                    (
                        (status != 'staff') ?
                        (
                            <li>
                              <Link to={"/seat"}>
                                <div className="parent-icon icon-color-5">
                                  <i className="bx bx-chair" />
                                </div>
                                <div className="menu-title">Seat</div>
                              </Link>
                            </li>
                        ) : ""
                    )
                }

                <li>
                  <Link to={"/slot"}>
                    <div className="parent-icon icon-color-5">
                      <i className="lni lni-layers" />
                    </div>
                    <div className="menu-title">Slot</div>
                  </Link>
                </li>

                <li>
                  <Link to={"/fees"}>
                    <div className="parent-icon icon-color-5">
                      <i className="lni lni-wallet" />
                    </div>
                    <div className="menu-title">Fees</div>
                  </Link>
                </li>

                {
                    (
                        (status != 'staff') ?
                        (
                            <li>
                              <Link to={"/staffDetails"}>
                                <div className="parent-icon icon-color-5">
                                  <i className="lni lni-users" />
                                </div>
                                <div className="menu-title">Staff</div>
                              </Link>
                            </li>
                        ) : ""
                    )
                }
                
              </ul>
            </div>
        </>
    )
}
