import React, { useState, useEffect, } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import { useSelector } from 'react-redux';
import moment from 'moment';
import {fetchStudentFeeReciptDetail,addFeeRecipt,searchStudent,editFeeRecipt,fetchStudentDetails,fetchStudentDueAmount} from '../../api/fees';
import { Link,useHistory} from "react-router-dom"

export default function Student(props) {
    const {match}= props;
    const {params}=match;
    const history = useHistory();
    const adminDetails = useSelector(state=>state.ins.details);
    const [studentId, setstudentId] = useState(params.id) 
    const [libFees, setlibFees] = useState(0)
    const [paymentReceiptNo, setPaymentReceiptNo] = useState("")
    const [paymentMode, setPaymentMode] = useState("cash")
    const [lockerFees, setLockerFees] = useState(0)
    const [registrationFees, setRegistrationFees] = useState(0)
    const [startTime, setStartTime] = useState("")
    const [paymentReceiptDate, setPaymentReceiptDate] = useState("")
    const [endTime, setEndTime] = useState("")
    const [mode,setMode] = useState("add");
    const [buttonType,setButtonType] = useState("Add");
    const[SnackBarMessage, setSnackBarMessage] = useState("")
    const[isSnackBarShow, setIsSnackBarShow] = useState(false)
    const [selectStudents,setSelectStudents] = useState([])
    const[showSelectForSlot, setShowSelectForSlot] = useState(false)
    const[addEditLoading,setAddEditLoading] = useState(false)
    const [dueAmount,setDueAmount] = useState(0);
    const [paidFees,setPaidFees] = useState(0);
    const mylabel = {color: "#5c00e6"};
    const border = {border: "2px solid #d1b3ff",padding: "30px",borderRadius:"10px"};

    const addFeeReciptCallback = (response) => {
        if (response.msg === "success") {
            setSnackBarMessage("Fee Recipt Added Successfully")
            setIsSnackBarShow(true)
        }else{
            setSnackBarMessage("Something went wrong")
            setIsSnackBarShow(true)
        }
        setAddEditLoading(false)
        history.push("/fees");
    }

    const editFeeReciptCallback = (response) => {
        if (response.msg === "success") {
            setSnackBarMessage("Fee Recipt Edited Successfully")
            setIsSnackBarShow(true)
        }else{
            setSnackBarMessage("Something went wrong")
            setIsSnackBarShow(true)
        }
        setAddEditLoading(false)
        history.push("/fees");
    }

    const addEditStudentHandler = ()=>{
           if(mode==="add"){
                if(!addEditLoading)
                {
                    setAddEditLoading(true)
                    addFeeRecipt(studentId,"",startTime,endTime,libFees,lockerFees,registrationFees,dueAmount,paidFees,paymentReceiptNo,paymentReceiptDate,addFeeReciptCallback)
                    history.push("/studentDetails/0");
                }
            }
    }

    const closeSnack=()=>{
        setIsSnackBarShow(false)
    }

    const fetchStudentDueDetailsCallback = (response) => {
        if (response.msg === "success") {
            setDueAmount(response.data)
        }
    }

    useEffect(()=>{
        if(params.id) {
            fetchStudentDueAmount(params.id,fetchStudentDueDetailsCallback);
        }
    },[params.id])

    const paymentDate = (value) => {
        setStartTime(value);
        let myDate = new Date(value);
        let d = myDate.getDate();
        myDate.setMonth(myDate.getMonth() + 1);
        myDate.setDate(myDate.getDate() - 1);
        value = myDate.getFullYear()+"-";
        if (myDate.getMonth() < 10) {
            value += "0";
        }
        value += (myDate.getMonth() + 1)+"-";
        if (myDate.getDate() < 10) {
            value += "0";
        }
        value += myDate.getDate();
        setEndTime(value);
    }

    return(

        <div className="wrapper container ">
            <div className="page-breadcrumb d-none d-md-flex align-items-center mb-3">
                <div className="breadcrumb-title pr-3">{buttonType} Fee Recipt</div>
                <div className="pl-3">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb mb-0 p-0">
                            <li className="breadcrumb-item">
                                <Link to={"/fees"}>
                                    <i className='bx bx-home-alt'></i>
                                </Link>
                            </li>
                        </ol>
                    </nav>
                </div>
            </div>

            <form>
                <div className="container mt-5" style={border}>
                    <div className="row">
                        <div className="col-3 form-floating mb-1 mt-2">
                         <h6><label htmlhtmlFor="studentNo">Student ID : MS-{params.id}</label></h6>
                        </div>
                    </div>

                    <div className="row" style={mylabel}>
                        {mode==="add"? (<div className="col-3 form-floating mb-1 mt-2"><b><label htmlFor="studentNo">Due Amount: <span className="text-dark">{dueAmount}</span></label></b></div>) : "" }
                    </div>

                    <div className="row">
                        <div className="col-2">
                            <div className="form-check mt-1">
                                <input name="exam_preparing" className="form-check-input" type="radio" value="online" checked={paymentMode.includes("online")} onChange={(e)=>setPaymentMode(e.target.value)} id="flexCheckDefault1"></input>
                                <label className="form-check-label" htmlFor="flexCheckDefault1">Online</label>
                            </div>
                        </div>
                        <div className="col-2">
                            <div className="form-check mt-1">
                                <input name="exam_preparing" className="form-check-input" type="radio" value="cash" checked={paymentMode.includes("cash")} onChange={(e)=>setPaymentMode(e.target.value)} id="flexCheckDefault2"></input>
                                <label className="form-check-label" htmlFor="flexCheckDefault2">Cash</label>
                            </div>
                        </div>
                    </div>

                    <div className="row" style={mylabel}>
                        <div className="col form-floating mb-3 mt-2">
                            <b><label htmlFor="aaadharNo">Payment Receipt No:</label></b>
                            <input type="text" className="form-control" value={paymentReceiptNo} onChange={e => setPaymentReceiptNo((!e.target.value) ? "" : e.target.value)} placeholder="Payment Receipt No." />
                        </div>
                        <div className="col form-floating mb-3 mt-2">
                            <b><label htmlFor="endTime">Payment Receipt Date</label></b>
                            <input type="date" data-date-format="DD MMMM YYYY" className="form-control" value={paymentReceiptDate} onChange={e => {setPaymentReceiptDate(e.target.value)} } />
                        </div>
                    </div>

                    <div className="row mt-2" style={mylabel}>
                        <div className="col form-floating mb-3 mt-2">
                            <b><label htmlFor="startTime">Start Date</label></b>
                            <input type="date" className="form-control" value={startTime} onChange={e => {paymentDate(e.target.value)}} placeholder="Start Time" id="startTime"/>
                        </div>
                        <div className="col form-floating mb-3 mt-2">
                            <b><label htmlFor="endTime">End Date</label></b>
                            <input type="date" className="form-control" value={endTime} onChange={e => {setEndTime(e.target.value)} } placeholder="End Time" id="endTime"/>
                        </div>
                    </div>

                    <div className="row" style={mylabel}>
                        <div className="col form-floating mb-3 mt-2">
                            <b><label htmlFor="aaadharNo">Registration Fees</label></b>
                            <input type="number" className="form-control" value={registrationFees} onChange={e => setRegistrationFees((!e.target.value) ? 0 : e.target.value.replace(/^0+/, ''))} placeholder="Registration Fees" id="aaadharNo"/>
                        </div>
                        <div className="col form-floating mb-3 mt-2">
                            <b><label htmlFor="lockerFees">Locker Fees</label></b>
                            <input type="number" className="form-control" value={lockerFees} onChange={e => setLockerFees((!e.target.value) ? 0 : e.target.value.replace(/^0+/, ''))} placeholder="Locker Fees" id="lockerFees"/>
                        </div>
                        <div className="col form-floating mb-3 mt-2">
                            <b><label htmlFor="aaadharNo">Library Fees</label></b>
                            <input type="number" className="form-control" value={libFees} onChange={e => setlibFees((!e.target.value) ? 0 : e.target.value.replace(/^0+/, ''))} placeholder="Library Fees" id="aaadharNo"/>
                        </div>
                    </div>
                    <div className="row" style={mylabel}>
                        <div className="col form-floating mb-1 mt-2">
                            <h6 className="float-right"><label>Total Amount: {parseFloat(libFees) + parseFloat(lockerFees) + parseFloat(registrationFees) + parseFloat(dueAmount)}</label></h6>
                        </div>
                    </div>
                    <div className="row" style={mylabel}>
                        <div className="col form-floating mb-3 mt-2">
                            <div className="float-right">
                                <b><label htmlFor="aaadharNo">Paid Amount</label></b>
                                <input type="number" className="form-control" value={paidFees} onChange={e => setPaidFees((!e.target.value) ? 0 : e.target.value.replace(/^0+/, ''))} placeholder="Paid Amount" id="aaadharNo"/>
                            </div>
                        </div>
                    </div>
                    <div className="row" style={mylabel}>
                        <div className="col form-floating mb-1 mt-2">
                            <h6 className="float-right"><label>Final Due Amount: {parseFloat(libFees) + parseFloat(lockerFees) + parseFloat(registrationFees) + parseFloat(dueAmount) - parseFloat(paidFees)}</label></h6>
                        </div>
                    </div>

                    <div className="btn-group mt-5 w-100">
                        <button type="button" onClick={addEditStudentHandler} className="btn btn-primary btn-block" >
                            {addEditLoading?(
                                <div className="spinner-border text-light" role="status">   <span className="sr-only">Loading...</span>
                                </div>
                            ):(
                                <span>{buttonType} Fee Recipt</span>
                            )}
                        </button>
                    </div>

                </div>
            </form>

            <Snackbar
                open={isSnackBarShow}
                onClose={(e)=>closeSnack(e)}
                TransitionComponent="TransitionUp"
                message={SnackBarMessage}
            />
        </div>
    )
}
