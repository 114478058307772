import {serverApiUrl} from '../'


export const totalStudent = (adminId,callback) => 
{
    let formaData = new FormData();
    formaData.append("admin_id",adminId);
    formaData.append("total_student",true);
    fetch(serverApiUrl+"dashboard.php",{
        method: 'POST',
        body:formaData
    }).then(response =>response.json())
    .then(response=>callback(response))
    .catch(errr=>console.log(errr))
}


export const totalSeats = (adminId,callback) => 
{
    let formaData = new FormData();
    formaData.append("admin_id",adminId);
    formaData.append("total_seats",true);
    fetch(serverApiUrl+"dashboard.php",{
        method: 'POST',
        body:formaData
    }).then(response =>response.json())
    .then(response=>callback(response))
    .catch(errr=>console.log(errr))
}


export const occupiedSeats = (callback) => 
{
    let formaData = new FormData();
    formaData.append("occupied_seats",true);
    fetch(serverApiUrl+"dashboard.php",{
        method: 'POST',
        body:formaData
    }).then(response =>response.json())
    .then(response=>callback(response))
    .catch(errr=>console.log(errr))
}

export const deleteSeats = (callback) => 
{
    let formaData = new FormData();
    formaData.append("delete_seat_slot",true);
    fetch(serverApiUrl+"dashboard.php",{
        method: 'POST',
        body:formaData
    }).then(response =>response.json())
    .then(response=>callback(response))
    .catch(errr=>console.log(errr))
}


export const seatDetails = (callback) => 
{
    let formaData = new FormData();
    formaData.append("seat_details",true);
    fetch(serverApiUrl+"dashboard.php",{
        method: 'POST',
        body:formaData
    }).then(response =>response.json())
    .then(response=>callback(response))
    .catch(errr=>console.log(errr))
}

export const notificationStudent = (callback) => 
{
    let formaData = new FormData();
    formaData.append("notification_student",true);
    fetch(serverApiUrl+"dashboard.php",{
        method: 'POST',
        body:formaData
    }).then(response =>response.json())
    .then(response=>callback(response))
    .catch(errr=>console.log(errr))
}


export const fetchFeesAmount = (fromDate = null,toDate = null,callback) => 
{
    let formaData = new FormData();
    formaData.append("from_date",fromDate);
    formaData.append("to_date",toDate);
    formaData.append("fetch_fees",true);
    fetch(serverApiUrl+"dashboard.php",{
        method: 'POST',
        body:formaData
    }).then(response =>response.json())
    .then(response=>callback(response))
    .catch(errr=>console.log(errr))
}

export const fetchSeatDetails = (seat_number,callback) => 
{
    let formaData = new FormData();
    formaData.append("seat_number",seat_number);
    formaData.append("fetch_seat_details",true);
    fetch(serverApiUrl+"dashboard.php",{
        method: 'POST',
        body:formaData
    }).then(response =>response.json())
    .then(response=>callback(response))
    .catch(errr=>console.log(errr))
}