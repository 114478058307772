    import {serverApiUrl} from '../';

    export const fetchOfficeDetails = (adminId,callback) => 
    {
        let formaData  = new FormData();
        formaData.append("admin_id",adminId);
        formaData.append("fetch_office_details",true);
        fetch(serverApiUrl+"office.php",{
            method: 'POST',
            body:formaData
        }).then(response =>response.json())
        .then(response=>callback(response))
        .catch(errr=>console.log(errr))
    }


export const editOfficeDetails = (adminId,libName,officeNo,logo,signStamp,officeAddress,callback) => 
{
    let formaData  = new FormData();
    formaData.append("admin_id",adminId);
    formaData.append("lib_name",libName);
    formaData.append("office_no",officeNo);
    formaData.append("logo",logo);
    formaData.append("sign_stamp",signStamp);
    formaData.append("office_address",officeAddress);
    formaData.append("update_office_details",true);
    fetch(serverApiUrl+"office.php",{
        method: 'POST',
        body:formaData
    }).then(response =>response.json())
    .then(response=>callback(response))
    .catch(errr=>console.log(errr))
}