import React, { useState} from 'react'
import { searchSlot} from '../../api/student';

export default function RenderSingleTime(props) {
    const {index,mylabel,mode,data,startTime,endTime,updateSetSeatNo}=props
    const [showSelectForSlot, setShowSelectForSlot] = useState(false)
    const [emptySlots,setEmptySlots] = useState([])
    const [tempStartTime,setTempStartTime] = useState(startTime[index])
    const [tempEndTime,setTempEndTime] = useState(endTime[index])

    const tempStartTimeUpdate = (index,value) => {
        setTempStartTime(value)
        startTime[index] = value
    }

    const tempEndTimeUpdate = (index,value) => {
        setTempEndTime(value)
        endTime[index] = value
    }

    const searchSlotCallback = (response) => {
        if(response.msg === "success"){
            setShowSelectForSlot(true);
            setEmptySlots(response.data);
        }
    }

    const searchSlotHandler = (e)=>{
        searchSlot(startTime[e],endTime[e],e,searchSlotCallback);
    }

    return(
        <div className="row mt-2" style={mylabel}>
            <div className="col form-floating mb-3 mt-2">
                <b><label for="startTime">Start Time</label></b>
                <input type="time" className="form-control" value={tempStartTime} onChange={(e) => {tempStartTimeUpdate(index,e.target.value);}} placeholder="Start Time" id="startTime"/>
            </div>
            <div className="col form-floating mb-3 mt-2">
                <b><label for="endTime">End Time</label></b>
                <input type="time" className="form-control" value={tempEndTime} onChange={(e) => {tempEndTimeUpdate(index,e.target.value);} } placeholder="End Time" id="endTime"/>
            </div>
            {mode==="edit"?(
                <div className="col-3 form-floating mb-3 mt-4 ">
                    <h6 className=""><label for="studentNo">Seat No : {data.d_sno}</label></h6>
                </div>
            ):(null)}
            <div className="col-3 mt-4">
                <b><label for="endTime">&nbsp;</label></b>
                <button onClick={(e) => searchSlotHandler(index)} className="btn btn-secondary dropdown-toggle float-right" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                    Search Available Slots
                </button>
            </div>
            <div className="col">
                <b><label for="endTime">&nbsp;</label></b>
                {showSelectForSlot?(
                    emptySlots?(
                        <select className="form-control" onChange={(e) =>updateSetSeatNo(index,e.target.value)} >
                            <option value={null}>Select</option>
                                
                            {emptySlots.map((item,i)=>(
                                <option value={item.s_no} selected={item.s_no===data.s_no}>{item.seat_number}</option>
                            ))}
                        </select>
                    ):(<div className="alert alert-danger" role="alert">
                    No slots are available . 
                  </div>)
                ):(null)}
            </div>
        </div>
    )
}
