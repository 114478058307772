import React, { useState, useEffect } from 'react';
import {fetchSeatDetails} from '../../api/dashboard';
import Modal, { ModalBody, ModalFooter, ModalHeader } from '../../components/modal/modal';
import MaterialTable from 'material-table'

function SeatItem(props) {

    const {item}=props;
    const [isAddModalVisible,setisAddModalVisible]=useState(false)
    const [seatDetailsData, setSeatDetailsData] = useState([])

    const fetchSeatDetailsCallback = (response) => {
        if (response.msg === "success") {
            setSeatDetailsData(response.data)
            setisAddModalVisible(true)
        }
    }

    const fetchSeatDetailsHandler = ()=>{
        fetchSeatDetails(item.seat_number,fetchSeatDetailsCallback)
    }

    const columns = [
        {title:'Studnet Id',field:'id',render:(row) => "MS-"+row.id},
        {title:'Name',field:'name'},
        {title:'Father Name',field:'f_name'},
        {title:'Slot time',field:'time'},
    ]


    const switchSeatIcon=()=>
    {
        if(item.student_id) {
            return(
                <>
                    <div className="col-2 col-lg-2 col-xl-2" onClick={fetchSeatDetailsHandler}>
                        <div className="icon-box qp0pm4-0 pByfF" tabIndex="694">
                            <span className="mb-0 mt-0 p-1 pr-2 pl-2 bg-primary radius-15" style={{color:'white'}}>{item.seat_number}</span>   
                            <div className="icon-box-inner  mt-0 pt-1">
                                <div className="icon-base"><i className="fadeIn animated bx bx-user-pin" style={{fontSize:50,color:'red'}}></i></div>
                                <div className="icon-box-name" style={{fontSize:13}}>{item.seat_student}</div>
                            </div>
                        </div>
                    </div>
                    <Modal
                        visible={isAddModalVisible} 
                        setModalVisible={setisAddModalVisible} 
                        modalId={item.seat_number} 
                    >
                        <form>
                            <ModalHeader>
                                <h5 className="modal-title">Student Details</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">    <span aria-hidden="true">&times;</span>
                                </button>
                            </ModalHeader>   
                            <ModalBody> 
                                <MaterialTable
                                    data = {seatDetailsData}
                                    columns = {columns}
                                    options={{
                                        exportButton: true,
                                        filtering: false,
                                        search: false,
                                        draggable:false,
                                        pagination:false,
                                        showTitle: false,
                                        paging:false,
                                        tableLayout: "fixed"
                                    }}
                                    localization={{
                                        body: {
                                            emptyDataSourceMessage: 'No records to display',
                                        }
                                    }}
                                />
                            </ModalBody> 
                        </form>
                    </Modal>
                </>
                    
            )
        }else{
            return(
                <>
                    <div className="col-2 col-lg-2 col-xl-2" onClick={fetchSeatDetailsHandler}> 
                        <div className="icon-box qp0pm4-0 pByfF" tabIndex="694">
                            <span className="mb-0 mt-0 p-1 pr-2 pl-2 bg-primary radius-15" style={{color:'white'}}>{item.seat_number}</span>
                                <div className="icon-box-inner">
                                    <div className="icon-base" >
                                        <i className="fadeIn animated bx bx-message-alt" style={{fontSize:50,color:'green'}}></i>
                                    </div>
                                <div className="icon-box-name">Vacant</div>
                            </div>
                        </div>
                    </div>
                    <Modal
                        visible={isAddModalVisible} 
                        setModalVisible={setisAddModalVisible} 
                        modalId={item.seat_number} 
                    >
                        <form>
                            <ModalHeader>
                                <h5 className="modal-title">Student Details</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">    <span aria-hidden="true">&times;</span>
                                </button>
                            </ModalHeader>   
                            <ModalBody> 
                                <MaterialTable
                                    data = {seatDetailsData}
                                    columns = {columns}
                                    options={{
                                        exportButton: true,
                                        filtering: false,
                                        search: false,
                                        draggable:false,
                                        pagination:false,
                                        showTitle: false,
                                        paging:false,
                                        tableLayout: "fixed"
                                    }}
                                    localization={{
                                        body: {
                                            emptyDataSourceMessage: 'No records to display',
                                        }
                                    }}
                                />
                            </ModalBody> 
                        </form>
                    </Modal>
                </>
            )
        }
    }
    return (
        switchSeatIcon()
    )
}

export default SeatItem
