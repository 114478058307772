import React, { useState, useEffect } from 'react';
import {totalStudent,totalSeats,occupiedSeats,seatDetails,fetchFeesAmount,notificationStudent} from '../../api/dashboard';
import { useSelector } from 'react-redux';
import SeatItem from './SeatItem';
import { Shimmer } from 'react-shimmer';
import MaterialTable from 'material-table'



 const Dashboard = props => {
    const adminDetails = useSelector(state=>state.ins.details);
    const authStatus = useSelector((state)=>state.ins.role)
    const [status, setStatus] = useState("staff");

    useEffect(()=>{
        setStatus(authStatus)
    },[authStatus])

    const columns = [
        {title:'Studnet Id',field:'student_id',render:(row) => "MS-"+row.student_id,type:'numeric'},
        {title:'Name',field:'name'},
        {title:'End Date',field:'to_month'},
    ]

    const [dueStudentData, setDueStudentData] = useState([]);
    const [totalStudents,setTotalStudents] = useState("")
    const [totalSeat,setTotalSeat] = useState("")
    const [occupiedSeat,setOccupiedSeat] = useState("")
    const [seatsData,setSeatsData] = useState([]);
    const [showSeatShimmer,setShowSeatShimmer] = useState(true);
    const [showDetailShimmer,setShowDetailShimmer] = useState(true);
    const [fromDate,setFromDate] = useState("");
    const [toDate,setToDate] = useState("");
    const [totalFeesAmount,setTotalFeesAmount] = useState("Rs. 0.00");
    const [dueFeesAmount,setDueFeesAmount] = useState("Rs. 0.00");
    const totalStudentCallback = (response) =>{
      if(response.msg==="success"){
        
        setTotalStudents(response.data.total_count)
      }
    }

    const totalSeatsCallback = (response) =>{
      if(response.msg==="success"){
        setTotalSeat(response.data.total_count)
      }
    }

    const occupiedSeatsCallback = (response) =>{
      if(response.msg==="success"){
        setOccupiedSeat(response.data.total_count)
      }
    }
    const seatDetailsCallback=(response)=>
    {
        if(response.msg==="success")
        {
          setSeatsData(response.data)
        }
        setShowSeatShimmer(false)
        setShowDetailShimmer(false);
    }

    const notificationStudentCallback=(response)=>
    {
        if(response.msg==="success")
        {
          setDueStudentData(response.data)
        }
    }
    
    useEffect(()=>{
      totalStudent(adminDetails.s_no,totalStudentCallback)
      totalSeats(adminDetails.s_no,totalSeatsCallback)
      occupiedSeats(occupiedSeatsCallback);
      seatDetails(seatDetailsCallback);
      notificationStudent(notificationStudentCallback);
      //fetchFeesAmount(null,null,fetchFeesCallback)
    },[adminDetails.s_no])

    const fetchFeesCallback = (response) => {
        if(response.msg==="success")
        {
            let totalAmount = (response.data.total_fees > 0) ? response.data.total_fees : 0;
            setTotalFeesAmount("Rs. "+totalAmount)
            let dueAmount = (response.data.due_fees > 0) ? response.data.due_fees : 0;
            setDueFeesAmount("Rs. "+dueAmount)
        }
    }

    const fetchFeesHandler = ()=>{
        fetchFeesAmount(fromDate,toDate,fetchFeesCallback)
    }

    return(
      <div>
        <section>
        <h3 className="mb-2">Summary </h3>
        {showDetailShimmer?(
                          <div style={{height:200}}>

                            <div className="row">
                              <div className="col-4">
                                <Shimmer width={290} height={160} />
                              </div>
                              <div className="col-4">
                                <Shimmer width={290} height={160} />
                              </div>
                              <div className="col-4">
                                <Shimmer width={290} height={160} />
                              </div>
                            </div>
                          </div>
                      ):(
                        <div className="row">
                            <div className="col-12 col-lg-12 col-xl-12">
                              <div className="card-deck flex-column flex-lg-row">
                                <div className="card radius-15 bg-info">
                                  <div className="card-body text-center">
                                    <div className="widgets-icons mx-auto rounded-circle bg-white"><i className='bx bx-group'></i>
                                    </div>
                                    <h4 className="mb-0 font-weight-bold mt-3 text-white">{totalStudents}</h4>
                                    <p className="mb-0 text-white">Total Students</p>
                                  </div>
                                </div>
                                <div className="card radius-15 bg-wall">
                                  <div className="card-body text-center">
                                    <div className="widgets-icons mx-auto bg-white rounded-circle"><i className="fadeIn animated bx bx-chair"></i>
                                    </div>
                                    <h4 className="mb-0 font-weight-bold mt-3 text-white">{totalSeat}</h4>
                                    <p className="mb-0 text-white">Total Seats</p>
                                  </div>
                                </div>
                                <div className="card radius-15 bg-rose">
                                  <div className="card-body text-center">
                                    <div className="widgets-icons mx-auto bg-white rounded-circle"><i className="fadeIn animated bx bx-timer"></i>
                                    </div>
                                    <h4 className="mb-0 font-weight-bold mt-3 text-white">{occupiedSeat}</h4>
                                    <p className="mb-0 text-white">Currently Filled Seats</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                        </div>
                      )}
        
        </section>
        {
            (status != 'staff') ? (
                <section>
                    <h3 className="mb-2 mt-3">Incomes & Dues</h3>
                    <div className="card radius-15">
                        <div className="card-body">
                            <div className="row d-flex justify-content-center">
                                <div className="row">
                                    <div className="col form-floating">
                                        <b><label for="startTime">From</label></b>
                                        <input type="date" className="form-control" onChange={(e)=>setFromDate(e.target.value)} />
                                    </div>
                                    <div className="col form-floating">
                                        <b><label for="endTime">To</label></b>
                                        <input type="date" className="form-control" onChange={(e)=>setToDate(e.target.value)}/>
                                    </div>
                                    <div className="col form-floating">
                                        <b><label for="endTime">&nbsp;</label></b>
                                        <button className="btn btn-primary form-control" onClick={fetchFeesHandler}>Fetch</button>
                                    </div>
                                </div>
                            </div>
                            <div className="row d-flex justify-content-center">
                                <div className="row">
                                    <div className="col-5 form-floating">
                                        <br />
                                        <b><label for="endTime">Total Fees</label></b>
                                        <input type="text" className="form-control" value={totalFeesAmount} disabled/>
                                    </div>
                                    <div className="col-5 form-floating">
                                        <br />
                                        <b><label for="endTime">Due Fees</label></b>
                                        <input type="text" className="form-control" value={dueFeesAmount} disabled/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            ) : ""
        }
        <section>
            <MaterialTable
                title="Notification for due student"
                data = {dueStudentData}
                columns = {columns}
                options={{
                    exportButton: true,
                    filtering: true,
                    search: false,
                    pageSize: 5,
                    pageSizeOptions:[5, 10, 20, 50],
                    draggable:false,
                    paginationType: "stepped" 
                }}
                localization={{
                    body: {
                        emptyDataSourceMessage: 'No records to display',
                    }
                }}
            />
        </section>
        <section>
            <h3 className="mb-2 mt-3">Seat Chart</h3>
            <div className="card radius-15">
                <div className="card-body">
                    <div className="row d-flex justify-content-center">
                          {showSeatShimmer?(
                              <div style={{height:200}}>

                                    <div className="row" style={{height:100}}> 
                                        <div className="col-2">
                                          <Shimmer width={110} height={90} />
                                        </div>
                                        <div className="col-2">
                                          <Shimmer width={110} height={90} />
                                        </div>
                                        <div className="col-2">
                                          <Shimmer width={110} height={90} />
                                        </div>
                                        <div className="col-2">
                                          <Shimmer width={110} height={90} />
                                        </div>
                                        <div className="col-2">
                                          <Shimmer width={110} height={90} />
                                        </div>
                                        <div className="col-2">
                                          <Shimmer width={110} height={90} />
                                        </div> 
                                    </div>
                                    <div className="row" style={{height:60}}>
                                        <div className="col-2">
                                          <Shimmer width={110} height={90} />
                                        </div>
                                        <div className="col-2">
                                          <Shimmer width={110} height={90} />
                                        </div>
                                        <div className="col-2">
                                          <Shimmer width={110} height={90} />
                                        </div>
                                        <div className="col-2">
                                          <Shimmer width={110} height={90} />
                                        </div>
                                        <div className="col-2">
                                          <Shimmer width={110} height={90} />
                                        </div>
                                        <div className="col-2">
                                          <Shimmer width={110} height={90} />
                                        </div> 
                                    </div>
                              </div>
                          ):(
                            seatsData.map((item,index)=>(

                              <SeatItem item={item} key={index} index={index}/>
                            ))
                          )}
                    </div>
                </div>
            </div>
        </section>      

      </div>
      )
      
    }
export default Dashboard  