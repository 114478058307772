import {serverApiUrl} from '../';

export const fetchfeesDetails = (adminId,callback) => 
{
    let formaData  = new FormData();
    formaData.append("admin_id",adminId);
    formaData.append("fetch_fees_details",true);
    fetch(serverApiUrl+"fees.php",{
        method: 'POST',
        body:formaData
    }).then(response =>response.json())
    .then(response=>callback(response))
    .catch(errr=>console.log(errr))
}

export const fetchStudentDueAmount = (studentId,callback) => {
    let formaData  = new FormData();
    formaData.append("student_id",studentId);
    formaData.append("fetch_due_details",true);
    fetch(serverApiUrl+"fees.php",{
        method: 'POST',
        body:formaData
    }).then(response =>response.json())
    .then(response=>callback(response))
    .catch(errr=>console.log(errr))
}



export const addFeeRecipt = (studentId,period,from_month,end_month,lib_fees,locker_fees,register_fees,due_amount,paid_fees,paymentReceiptNo,paymentReceiptDate,paymentMode,callback) => 
{
    let formaData  = new FormData();
    formaData.append("student_id",studentId);
    formaData.append("period",period);
    formaData.append("from_month",from_month);
    formaData.append("end_month",end_month);
    formaData.append("lib_fees",lib_fees);
    formaData.append("locker_fees",locker_fees);
    formaData.append("register_fees",register_fees);
    formaData.append("due_amount",due_amount);
    formaData.append("paid_fees",paid_fees);
    formaData.append("paymentReceiptNo",paymentReceiptNo);
    formaData.append("paymentReceiptDate",paymentReceiptDate);
    formaData.append("paymentMode",paymentMode);
    formaData.append("insert_fee_recipt",true);
    fetch(serverApiUrl+"fees.php",{
        method: 'POST',
        body:formaData
    }).then(response =>response.json())
    .then(response=>callback(response))
    .catch(errr=>console.log(errr))
}


export const editFeeRecipt = (studentId,period,from_month,end_month,lib_fees,locker_fees,register_fees,due_amount,paidFees,paymentReceiptNo,paymentReceiptDate,paymentMode,feeId,callback) => 
{
    let formaData  = new FormData();
    formaData.append("feeId",feeId);
    formaData.append("student_id",studentId);
    formaData.append("period",period);
    formaData.append("from_month",from_month);
    formaData.append("end_month",end_month);
    formaData.append("lib_fees",lib_fees);
    formaData.append("locker_fees",locker_fees);
    formaData.append("register_fees",register_fees);
    formaData.append("due_amount",due_amount);
    formaData.append("paid_fees",paidFees);
    formaData.append("paymentReceiptNo",paymentReceiptNo);
    formaData.append("paymentReceiptDate",paymentReceiptDate);
    formaData.append("paymentMode",paymentMode);
    formaData.append("edit_fee_recipt",true);
    fetch(serverApiUrl+"fees.php",{
        method: 'POST',
        body:formaData
    }).then(response =>response.json())
    .then(response=>callback(response))
    .catch(errr=>console.log(errr))
}



export const searchStudent = (adminId,callback) => 
{
    let formaData  = new FormData();
    formaData.append("admin_id",adminId);
    formaData.append("search_student",true);
    fetch(serverApiUrl+"fees.php",{
        method: 'POST',
        body:formaData
    }).then(response =>response.json())
    .then(response=>callback(response))
    .catch(errr=>console.log(errr))
}

export const fetchStudentDetails = (id,callback) => 
{
    let formaData  = new FormData();
    formaData.append("student_id",id);
    formaData.append("fetch_student",true);
    fetch(serverApiUrl+"fees.php",{
        method: 'POST',
        body:formaData
    }).then(response =>response.json())
    .then(response=>callback(response))
    .catch(errr=>console.log(errr))
}

export const fetchStudentAllDetails = (id,paramsId,isLast,callback) => 
{
    let formaData  = new FormData();
    formaData.append("student_id",id);
    formaData.append("paramsId",paramsId);
    formaData.append("isLast",isLast);
    formaData.append("fetch_student_all_details",true);
    fetch(serverApiUrl+"fees.php",{
        method: 'POST',
        body:formaData
    }).then(response =>response.json())
    .then(response=>callback(response))
    .catch(errr=>console.log(errr))
}

export const fetchStudentFeeReciptDetail = (studentId,callback) => 
{
    let formaData  = new FormData();
    formaData.append("id",studentId);
    formaData.append("fetch_student_fee_detail",true);
    fetch(serverApiUrl+"fees.php",{
        method: 'POST',
        body:formaData
    }).then(response =>response.json())
    .then(response=>callback(response))
    .catch(errr=>console.log(errr))
}


export const deleteSingleFeeRecipt = (studentId,callback) => 
{
    let formaData  = new FormData();
    formaData.append("id",studentId);
    formaData.append("delete_fee_Recipt",true);
    fetch(serverApiUrl+"fees.php",{
        method: 'POST',
        body:formaData
    }).then(response =>response.json())
    .then(response=>callback(response))
    .catch(errr=>console.log(errr))
}

export const fetchSearchFeesDetails = (value,adminId,callback) => {
    let formaData  = new FormData();
    formaData.append("search",value);
    formaData.append("admin_id",adminId);
    formaData.append("search_fee_Recipt",true);
    fetch(serverApiUrl+"fees.php",{
        method: 'POST',
        body:formaData
    }).then(response =>response.json())
    .then(response=>callback(response))
    .catch(errr=>console.log(errr))
}