  const Footer = () => (
    <div className="footer">
      <p className="mb-0">
        {/* Syndash @2020 | Developed By :{" "} */}
        {/* <a href="https://themeforest.net/user/codervent" target="_blank">
          codervent
        </a> */}
      </p>
    </div>
  )

  export default Footer