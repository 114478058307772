import React, { useState, useEffect } from 'react'
import RenderSingleSlot from './RenderSingleSlot'
import { Shimmer } from 'react-shimmer'
import Snackbar from '@material-ui/core/Snackbar';
import {insertSlot,fetchSlots,deletesingleSlot} from '../../api/slot';
import { useSelector } from 'react-redux';
import Modal, { ModalBody, ModalFooter, ModalHeader } from '../../components/modal/modal';
import MaterialTable from 'material-table'
export default function Slot() {

    const adminDetails = useSelector(state=>state.ins.details);
    const [SlotData, setSlotData] = useState([]);
    const [studentId, setstudentId] = useState("")
    const [studentName, setStudentName] = useState("")
    const [startTime, setStartTime] = useState("")
    const [endTime, setEndTime] = useState("")
    const [showShimmer, setShowShimmer] = useState(true)
    const[SnackBarMessage, setSnackBarMessage] = useState("")
    const[isSnackBarShow, setIsSnackBarShow] = useState(false)
    const [isAddModalVisible,setisAddModalVisible]=useState(false)
    const [deleteLoading,setDeleteLoading] = useState(false);

    const fetchSlotCallback = (response) => {
        if (response.msg === "success") {
            let newData = [];
            response.data.map((item, index) => {
                newData.push({serial_no: index + 1, ...item});
            });
            setSlotData(newData) 
        }
        setShowShimmer(false)
        
    }


    useEffect(() => {
        fetchSlots(adminDetails.s_no,fetchSlotCallback)
    },[adminDetails.s_no])


    const deleteAtIndexCallback = (response,index) => {
        if (response.msg === "success") { 
            let instituteData_local = [...SlotData]
            instituteData_local.splice(index, 1);
            setSlotData(instituteData_local) 
            setSnackBarMessage("Slot Deleted Successfully")
            setIsSnackBarShow(true)
        }
        else {
            setSnackBarMessage("Something went wrong")
            setIsSnackBarShow(true)
        }
    }

    const deleteslot =(s_no,index)=>{ 
        deletesingleSlot(s_no,(response)=>deleteAtIndexCallback(response,index));
    }



    const addSlotCallback = (response) => {
        setisAddModalVisible(false);
        if (response.msg === "success") {  
            setSnackBarMessage("Slot Added Successfully")
            setIsSnackBarShow(true)
        }else{
            setSnackBarMessage("Something went wrong")
            setIsSnackBarShow(true)
        }
    }


    const addStudentSlot = () => {

        if (window.confirm('Are you sure to Add a Seat')) {
            insertSlot(61,studentId, studentName, startTime,endTime,addSlotCallback);

        } else {
            console.log('cancel mission add seat', studentId, studentName)

        }

    }

    const deleteSingleRow=(rowId,index) => {
        if(!deleteLoading)
        {
            setDeleteLoading(true)
            deleteslot(rowId,index);
        }
    }

    const closeSnack=()=>{
        setIsSnackBarShow(false)
    }

    const columns = [
        {title:'Student Id',field:'id',render:(row) => "MS-"+row.id,type:'numeric'},
        {title:'Seat No',field:'seat_number',type:'numeric'},
        {title:'End Date',field:'to_month'},
        {title:"Student Name",field:'name'},
        {title:"Father Name",field:'f_name'},
        {title:'Start Time',field:'start_time'},
        {title:'End Time',field:'end_time'},
        {title:'Action',field:'id',export: false,filtering: false,render:(row) => <button className="btn btn-danger m-1" onClick={()=> deleteSingleRow(row.s_no,row.serial_no)}><span>Delete</span></button>},
    ]


    return (
        <>
            <MaterialTable
                title="Slot Management"
                data = {SlotData}
                columns = {columns}
                options={{
                    exportButton: true,
                    filtering: true,
                    search: false,
                    pageSize: 10,
                    pageSizeOptions:[10, 20, 50, 100],
                    draggable:false,
                    paginationType: "stepped" 
                }}
                localization={{
                    body: {
                        emptyDataSourceMessage: 'No records to display',
                    }
                }}
            />

            <Modal
                visible={isAddModalVisible} 
                setModalVisible={setisAddModalVisible} 

                modalId={"testAddEditModal"} 
            >
                <form >
                    <ModalHeader>
                        <h5 className="modal-title">Add SLot</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">    <span aria-hidden="true">&times;</span>
                        </button>
                    </ModalHeader>   
                    <ModalBody> 
                        <input type="number" className="form-control mt-3" onChange={e => setstudentId(e.target.value)} placeholder="Student No" />
                        <input type="text" className="form-control mt-3" onChange={e => setStudentName(e.target.value)} placeholder="Student Name" />
                        <input type="datetime-local" className="form-control mt-3" onChange={e => setStartTime(e.target.value)} placeholder="Start Time" />
                        <input type="datetime-local" className="form-control mt-3" onChange={e => setEndTime(e.target.value)} placeholder="End Time" />
                    </ModalBody>
                    <ModalFooter>
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                            <button type="button" className="btn btn-primary" onClick={() => addStudentSlot()}>Save changes</button>
                    </ModalFooter> 
                 </form>
            </Modal>

            <Snackbar
                open={isSnackBarShow}
                onClose={(e)=>closeSnack(e)}
                TransitionComponent="TransitionUp"
                message={SnackBarMessage}
            />
        </>
    )
}
