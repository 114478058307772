import React ,{useEffect} from 'react';
export const ModalFooter = props => {
    return <div className="modal-footer">{props.children}</div>;
};
export const ModalHeader = props => {
    return <div className="modal-header">{props.children}</div>;
};
  
export const ModalBody = props => {
    return <div className="modal-body">{props.children}</div>;
};
const Modal = props => {
    const {visible,modalId,setModalVisible} = props;
    const modalBody = props.children;
 
    useEffect(()=>{

        if(visible)
        {
            window.$("#modal"+modalId).modal('show');
            
        }else
        {
            window.$("#modal"+modalId).modal('hide');
        }
    },[visible,modalId])
    useEffect(()=>{
        return window.$("#modal"+modalId).on('hide.bs.modal',()=>{
            setModalVisible(false)
        })
    },[modalId,setModalVisible])
    return (
            <div className="modal fade" id={"modal"+modalId} tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                         {modalBody} 
                    </div>
                </div>
            </div>);
};

 
export default Modal;