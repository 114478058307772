import React, { useState, useEffect } from 'react'
import RenderSingleSeat from './RenderSingleSeat'
import { Shimmer } from 'react-shimmer'
import Snackbar from '@material-ui/core/Snackbar';
import { insertSeats,deletemultipleSeats, fetchSeats} from '../../api/seat';
import { useSelector } from 'react-redux';
import Modal, { ModalBody, ModalFooter, ModalHeader } from '../../components/modal/modal';
import MaterialTable from 'material-table'
import {deletesingleSeat} from '../../api/seat';

export default function Seat() {


    const adminDetails = useSelector(state=>state.ins.details);
    const [seatData, setSeatData] = useState([]);
    
    const [totalSeats, setTotalSeats] = useState("")
    const [deleteSeats, setDeleteSeats] = useState("")
    const [showShimmer, setShowShimmer] = useState(true)
    const[SnackBarMessage, setSnackBarMessage] = useState("")
    const[isSnackBarShow, setIsSnackBarShow] = useState(false)
    const [isAddModalVisible,setisAddModalVisible]=useState(false)
    const[isDeleteModalVisible,setisDeleteModalVisible]=useState(false)
    const [addSeatLoading,setAddSeatLoading] = useState(false);
    const [deleteSeatLoading,setDeleteSeatLoading] = useState(false);
    const [deleteLoading,setDeleteLoading] = useState(false);

    useEffect(() => {
        fetchSeats(adminDetails.s_no,fetchSeatsCallback)
    },[adminDetails.s_no])

    const fetchSeatsCallback=(response)=>
    {
        if(response.msg ==="success")
        {
            let newData = [];
            response.data.map((item, index) => {
                newData.push({serial_no: index + 1, ...item});
            });
            setSeatData(newData)
           
        }
        setShowShimmer(false)
    }


    const addSeatCallback = (response) => {
        if (response.msg === "success") {
                // setDemo(data)
            fetchSeats(adminDetails.s_no,fetchSeatsCallback)
            setSnackBarMessage("Seats Added Successfully")
            setIsSnackBarShow(true)
        }else{
            setSnackBarMessage("Something went wrong")
            setIsSnackBarShow(true)
        }
        setAddSeatLoading(false)
    }

    const addSeatsHanlder = () => {

        if (window.confirm('Are you sure to Add Seats')) {
            if(!addSeatLoading)
            {
                setAddSeatLoading(true)
                insertSeats(totalSeats,adminDetails.s_no,addSeatCallback)
            }
            setisAddModalVisible(false);
        } 
        else {
            console.log('cancel mission add seats', totalSeats)
        }
    }


    const deleteRowInterface = (index)=>{
            let seat_data_local = [...seatData]
            seat_data_local.splice(index, 1);
            setSeatData(seat_data_local)
    }

    const deleteSeatCallback = (deleteSeats,response) => {
        if (response.msg === "success") {
            let seat_data_local = [...seatData]
            seat_data_local.splice(seatData.length-deleteSeats,deleteSeats);
            setSeatData(seat_data_local)
            setSnackBarMessage("Seats Deleated Successfully")
            setIsSnackBarShow(true)
            setisDeleteModalVisible(false)
        }
        else{
            setSnackBarMessage("Something went wrong")
            setIsSnackBarShow(true)
        }
        setDeleteSeatLoading(false)
    }

    
    const deleteSeatsHanlder=()=>
    {
        if(!deleteSeatLoading)
            {
                setDeleteSeatLoading(true)
                deletemultipleSeats(deleteSeats,(response)=>deleteSeatCallback(deleteSeats,response))
            }
        
    }

    const closeSnack=()=>{
        setIsSnackBarShow(false)
    }

    const columns = [
        {title:'Seat Id',field:'s_no',type:'numeric'},
        {title:"Seat Number",field:'seat_number',type:'numeric'},
        {title:'Action',field:'id',export: false,filtering: false,render:(row) => <button className="btn btn-danger m-1" onClick={()=> deleteSeat(row.s_no,row.serial_no)}><span>Delete</span></button>},
    ]

    const deletesingleCallback = (response,index) => {
        if (response.msg === "success") {
            deleteRowInterface(index);
            setSnackBarMessage("Seat Deleated Successfully")
            setIsSnackBarShow(true)
        }
        else{
            setSnackBarMessage("Something went wrong")
            setIsSnackBarShow(true)
        }
        setDeleteLoading(false);
    }

    const deleteSeat = (s_no,index) =>{
        if(!deleteLoading)
        {
            setDeleteLoading(true)
            deletesingleSeat(s_no,(response)=>deletesingleCallback(response,index))
        }
    }

    return (
        <>

            <div className="page-breadcrumb d-none d-md-flex align-items-center mb-3">
                <div className="ml-auto">
                    <button className="btn btn-primary" onClick={()=>setisAddModalVisible(true)}>Add Seats</button>
                    <button className="btn btn-danger ml-2" onClick={()=>setisDeleteModalVisible(true)}>Delete Multiple Seats</button>
                </div>
            </div>

            <MaterialTable
                title="Seat Management"
                data = {seatData}
                columns = {columns}
                options={{
                    exportButton: true,
                    filtering: true,
                    search: false,
                    pageSize: 10,
                    pageSizeOptions:[10, 20, 50],
                    draggable:false,
                    paginationType: "stepped" 
                }}
                localization={{
                    body: {
                        emptyDataSourceMessage: 'No records to display',
                    }
                }}
            />

            {/* Modal 4 add seats */}
            <Modal
                visible={isAddModalVisible} 
                setModalVisible={setisAddModalVisible} 
                modalId={"testAddEditModal"} 
            >
                <form >
                    <ModalHeader>
                        <h5 className="modal-title">Add Seats</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">	<span aria-hidden="true">&times;</span>
                        </button>
                    </ModalHeader>   
                    <ModalBody> 
                        <h6><label htmlFor="totalSeatsLabel">Enter number of seats you want to add</label></h6>
                        <input type="number" id="totalSeatsLabel" className="form-control mt-1" onChange={e => setTotalSeats(e.target.value)} placeholder="Total seats" />
                    </ModalBody>
                    <ModalFooter>
                        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                        <button type="button" className="btn btn-primary" onClick={() => addSeatsHanlder()}>
                            {addSeatLoading?(
                                <div className="spinner-border text-light" role="status">	<span className="sr-only">Loading...</span>
                                </div>
                                ):(
                                    <span>Add Seats</span>
                            )}
                            
                        </button>
                    </ModalFooter> 
                 </form>
            </Modal>

            

            {/* Modal 4 delete multiple seats */}
            <Modal
                visible={isDeleteModalVisible} 
                setModalVisible={setisDeleteModalVisible} 
                modalId={"testDeleteEditModal"} 
            >
                <form >
                    <ModalHeader>
                        <h5 className="modal-title">Delete Seats</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">	<span aria-hidden="true">&times;</span>
                        </button>
                    </ModalHeader>   
                    <ModalBody> 
                        <h6><label htmlFor="totalSeatsLabel">Enter number of seats you want to delete</label></h6>
                        <input type="number" id="totalSeatsLabel" className="form-control mt-1" onChange={e => setDeleteSeats(e.target.value)} placeholder="Total seats to be deleted" />
                    </ModalBody>
                    <ModalFooter>
                        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                        <button type="button" className="btn btn-primary" onClick={() => deleteSeatsHanlder()}>
                            {deleteSeatLoading?(
                                <div className="spinner-border text-light" role="status">	<span className="sr-only">Loading...</span>
                                </div>
                                ):(
                                    <span>Delete Seats</span>
                            )}
                            
                        </button>
                    </ModalFooter> 
                 </form>
            </Modal>


            <Snackbar
            open={isSnackBarShow}
            onClose={(e)=>closeSnack(e)}
            TransitionComponent="TransitionUp"
            message={SnackBarMessage}
            />

        </>
    )
}
