import React, { useState, useEffect, useRef } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import { useSelector } from 'react-redux';
import profile from '../../assets/images/profile_default.png';
import aadhar from '../../assets/images/aadhar_default.png';
import { addStaff,fetchEditDetails,editStaff} from '../../api/staff';
import { serverBaseUrl } from '../..';
import {useHistory,Link} from 'react-router-dom'

export default function Staff(props) {
    const {match}= props;
    const {params}=match;
    const history = useHistory();
    const adminDetails = useSelector(state=>state.ins.details);
    const [name, setName] = useState("") 
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [cPassword, setCPassword] = useState("")
    const [mode,setMode] = useState("add");
    const [buttonType,setButtonType] = useState("Add");
    const[SnackBarMessage, setSnackBarMessage] = useState("")
    const[isSnackBarShow, setIsSnackBarShow] = useState(false)
    const [addMoreData,setAddMoreData] = useState([])
    const[addEditLoading,setAddEditLoading] = useState(false)
    
    const mylabel = {color: "#5c00e6"};
    const border = {border: "2px solid #d1b3ff",padding: "30px",borderRadius:"10px"};
    
    const fetchEditDetailsCallback=(response)=>{
        if(response.msg==="success"){
            setName(response.data.user_name);
            setEmail(response.data.name);
        }
    }

    useEffect(()=>{
        if(params.id)
        {
            setMode("edit");
            fetchEditDetails(params.id,fetchEditDetailsCallback);
            setButtonType("Save");
        }
    },[params.id])

    const addStaffCallback = (response) => {
        if (response.msg === "success") {
            setSnackBarMessage("Staff Added Successfully")
            setIsSnackBarShow(true)
            history.push("/staffDetails");
        }else{
            setSnackBarMessage("Something went wrong")
            setIsSnackBarShow(true)
        }
        setAddEditLoading(false)
    }

    const editStaffCallback = (response) => {
        if (response.msg === "success") {
            setSnackBarMessage("Saved  Successfully")
            setIsSnackBarShow(true)
            history.push("/staffDetails");
        }else{
            setSnackBarMessage("Something went wrong")
            setIsSnackBarShow(true)
        }
        setAddEditLoading(false)
    }

    const addEditStaffHandler = ()=>{
        if (password === cPassword) {
            if(mode==="add"){
                if(!addEditLoading)
                {
                    setAddEditLoading(true)
                    addStaff(name,email,password,addStaffCallback)
                }
            }
            if(mode==="edit"){
                if(!addEditLoading)
                {
                    setAddEditLoading(true)
                    editStaff(params.id,name,email,password,editStaffCallback)
                }
            }
        } else {
            setSnackBarMessage("Password & Confirm Password not match.")
            setIsSnackBarShow(true)
        }
    }

    const closeSnack=()=>{
        setIsSnackBarShow(false)
    }

    return(

        <div className="wrapper container ">
            <div className="page-breadcrumb d-none d-md-flex align-items-center mb-3">
                <div className="breadcrumb-title pr-3">{buttonType} Staff details</div>
                <div className="pl-3">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb mb-0 p-0">
                            <li className="breadcrumb-item">
                                <Link to={"/staffDetails"}>
                                    <i className='bx bx-home-alt'></i>
                                </Link>
                            </li>
                        </ol>
                    </nav>
                </div>
            </div>

            <form>
                <div className="container mt-5" style={border}>
                    <div className="row" style={mylabel}>
                        {mode==="edit"?(
                            <div className="col-3 form-floating mt-2">
                             <h6><label htmlFor="studentNo">Id : <span className="text-dark">{params.id}</span></label></h6>
                            </div>
                        ):(null)}
                    </div>
                
                    <div className="row" style={mylabel}>
                        <div className="col form-floating">
                            <b><label htmlFor="staffName">Name</label></b>
                            <input type="text" className="form-control" id="staffName" value={name} onChange={e => setName(e.target.value)} placeholder="Name" />
                        </div>
                    </div>

                    <div className="row" style={mylabel}>
                        <div className="col form-floating mt-2">
                            <b><label htmlFor="staffEmail">Email</label></b>
                            <input type="text" className="form-control" id="staffEmail" value={email} onChange={e => setEmail(e.target.value)} placeholder="Email" />
                        </div>
                    </div>

                    <div className="row" style={mylabel}>
                        <div className="col form-floating mt-2">
                            <b><label htmlFor="staffPassword">Password</label></b>
                            <input type="password" className="form-control" id="staffPassword" value={password} onChange={e => setPassword(e.target.value)} placeholder="Password" />
                        </div>
                    </div>
                    <div className="row" style={mylabel}>
                        <div className="col form-floating mt-2">
                            <b><label htmlFor="staffCPassword">Confirm Password</label></b>
                            <input type="password" className="form-control" id="staffCPassword" value={cPassword} onChange={e => setCPassword(e.target.value)} placeholder="Confirm Password" />
                        </div>
                    </div>

                    <div className="btn-group mt-5 w-100">
                        <button type="button" onClick={addEditStaffHandler} className="btn btn-primary btn-block" >
                            {addEditLoading?(
                                <div className="spinner-border text-light" role="status">   <span className="sr-only">Loading...</span>
                                </div>
                            ):(
                                <span>{buttonType} Staff</span>
                            )}
                        </button>
                    </div>

                </div>
            </form>

            <Snackbar
                open={isSnackBarShow}
                onClose={(e)=>closeSnack(e)}
                TransitionComponent="TransitionUp"
                message={SnackBarMessage}
            />
        </div>
    )
}
