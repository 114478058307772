import React, { useState, useEffect } from 'react'
import RenderSingleStudent from './RenderSingleStudent'
import { Shimmer } from 'react-shimmer'
import Snackbar from '@material-ui/core/Snackbar';
import {fetchStudent,deleteSingleStudent,fetchSearchName,fetchSearchStudentId,fetchSearchStudentDetails} from '../../api/student';
import { useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import MaterialTable from 'material-table'
import { serverApiUrl } from '../..';

export default function StudentDetails(props) {
    const {match}= props;
    const {params}=match;
    const adminDetails = useSelector(state=>state.ins.details);

    const authStatus = useSelector((state)=>state.ins.role)
    const [status, setStatus] = useState("staff");

    useEffect(()=>{
        setStatus(authStatus)
    },[authStatus])

    const [StudentData, setStudentData] = useState([]);
    const [showShimmer, setShowShimmer] = useState(true)
    const[SnackBarMessage, setSnackBarMessage] = useState("")
    const[searchName, setSearchName] = useState("")
    const[searchStudentId,setSearchStudentId] = useState("")
    const[typeStatus,setTypeStatus] = useState("")
    const[isSnackBarShow, setIsSnackBarShow] = useState(false)
    const [searchValue,setSearchValue] = useState("")
    const [deleteLoading,setDeleteLoading] = useState(false);

    const typeStyle = {textAlign: "center"};

    const deleteSingleRow=(rowId,index) => {
        if(!deleteLoading) {
            deleteStudent(rowId,index);
        }
    }

    const searchBoxHandler = (value) => {
        setSearchValue(value);
        if (adminDetails.s_no && params.id) {
            if (value) {
                fetchSearchStudentDetails(value,adminDetails.s_no,params.id,fetchStudentCallback)
            } else {
                fetchStudent(adminDetails.s_no,params.id,fetchStudentCallback)
            }
        }
    }

    const fetchStudentCallback = (response) => {
        if (response.msg === "success") {
            setStudentData(response.data)
          
        }
        if(response.msg === "error" && response.error === "no data"){
            setStudentData([]);
        }
        setShowShimmer(false)
    }

    const fetchClearStudentCallback = (response) => {
        if (response.msg === "success") {
            let newData = [];
            response.data.map((item, index) => {
                newData.push({serial_no: index + 1, ...item});
            });
            setStudentData(newData)
            setShowShimmer(false)
            setSearchStudentId("");
            setSearchName("");
        }
        if(response.msg === "error" && response.error === "no data"){
            setStudentData([]);
        }
    }


    useEffect(() => {
        if(params.id === '0'){
            setTypeStatus("All Students");
        }
        else if(params.id === '1'){
            setTypeStatus("Paid Students");
        }
        else if(params.id === '2'){
            setTypeStatus("Due Students")
        }
        
        fetchStudent(adminDetails.s_no,params.id,fetchStudentCallback)
        
    },[params.id,adminDetails.s_no])

    const searchStudentNameHandler = ()=>{
        fetchSearchName(adminDetails.s_no,searchName,fetchStudentCallback)
    }

    const searchStudentIdHandler = ()=>{
        fetchSearchStudentId(adminDetails.s_no,searchStudentId,fetchStudentCallback)
    }

    const seachAllStudentHandler = ()=>{
        fetchStudent(adminDetails.s_no,"",fetchClearStudentCallback)
    }

    const deleteAtIndexCallback = (response,index) => {
        if (response.msg === "success") { 
            fetchStudent(adminDetails.s_no,params.id,fetchStudentCallback)
            setSnackBarMessage("Student Deleted Successfully")
            setIsSnackBarShow(true)
        }
        else {
            setSnackBarMessage("Something went wrong")
            setIsSnackBarShow(true)
        }
    }

    const deleteStudent =(student_id,index)=>{ 
        deleteSingleStudent(student_id,(response)=>deleteAtIndexCallback(response,index));
    }

    const closeSnack=()=>{
        setIsSnackBarShow(false)
    }

    const columns = [
        {title:'Studnet Id',field:'id',render:(row) => "MS-"+row.id,type:'numeric'},
        {title:'Reg. Date',field:'created_time',type:'date',dateSetting:{locale:'en-GB'}},
        {title:'Name',field:'name'},
        {title:'Father Name',field:'f_name'},
        {title:'Present Address',field:'present_add'},
        {title:"Mobile No",field:'mobile_no',type:'numeric'},
    ]

    if (params.id !== '2') {
        columns.push(
            {title:"Slot Time => Seat Number",field:'time',render:(row) => <><td align="center" dangerouslySetInnerHTML={{ __html: row.time }} /></>}
        );
    }
    columns.push(
        {title:"Locker Number",field:'locker',render:(row) => (row.locker_no != 0) ? row.locker_no : "Not taken"},
    );
    if (params.id === '2') {
        columns.push(
            {title:"Due Amount",field:'due_fees',type:'currency',currencySetting:{ currencyCode:'INR'}},
        );
    }
    columns.push(
        {
            title:"Id Card",
            render:(row) =>
            <>
                <a className="btn btn-success m-1" rel="noreferrer" href={serverApiUrl+row.id_card_location} target="_blank">
                    View
                </a>
            </>
        },
        {
            title:"Fee Management",
            render:(row) =>
            <>
                <Link to={"/addRecipt/"+row.id}>
                    <button className="btn btn-secondary m-1"  data-toggle="modal" data-target={"#exampleModalCentered"+row.serial_no}>
                        Pay Fee
                    </button>
                </Link>
                <Link to={"/fees/"+row.id}>
                    <button className="btn btn-warning m-1"  data-toggle="modal" data-target={"#exampleModalCentered"+row.serial_no}>
                        Fee History
                    </button>
                </Link>
            </>
        },
    );

    if (params.id !== '2' && status != 'staff') {
        columns.push(
            {
                title:"Action",
                render:(row) =>
                <>
                    <button className="btn btn-danger m-1" onClick={()=> deleteSingleRow(row.id,row.serial_no)}>
                        {deleteLoading?(
                            <div className="spinner-border text-light" role="status">   <span className="sr-only">Loading...</span>
                            </div>
                        ):(
                            <span>Delete</span>
                        )}
                    </button>
                    <Link to={"/student/"+row.id}>
                        <button className="btn btn-info m-1"  data-toggle="modal" data-target={"#exampleModalCentered"+row.serial_no}>
                            Edit
                        </button>
                    </Link>
                </>
            },
        );
    }


    return (
        <>

            <div className="page-breadcrumb d-none d-md-flex align-items-center mb-3">
                <div className="ml-auto">
                    <Link to={"/student"}>
                        <button className="btn btn-primary">Add</button>
                    </Link>
                </div>
            </div>

            <div className="card mt-20">
                <div className="card-body">
                    <div className="table-responsive">
                        <MaterialTable
                            title={typeStatus}
                            data = {StudentData}
                            columns = {columns}
                            options={{
                                exportButton: true,
                                filtering: true,
                                search: false,
                                pageSize: 10,
                                pageSizeOptions:[10, 20, 50],
                                draggable:false,
                                paginationType: "stepped" 
                            }}
                            localization={{
                                body: {
                                    emptyDataSourceMessage: 'No records to display',
                                }
                            }}
                        />
                    </div>
                </div>
            </div>

            <Snackbar
                open={isSnackBarShow}
                onClose={(e)=>closeSnack(e)}
                TransitionComponent="TransitionUp"
                message={SnackBarMessage}
            />
        </>
    )
}
