import React, { useState, useEffect, useRef } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import RenderSingleTime from './RenderSingleTime';
import { useSelector } from 'react-redux';
import profile from '../../assets/images/profile_default.png';
import aadhar from '../../assets/images/aadhar_default.png';
import { addStudent,fetchEditDetails,editStudent} from '../../api/student';
import { serverBaseUrl } from '../..';
import {useHistory,Link} from 'react-router-dom'

export default function Student(props) {
    const {match}= props;
    const {params}=match;
    const history = useHistory();
    const adminDetails = useSelector(state=>state.ins.details);
    const [viewTimeLayout,setViewTimeLayout] = useState(false)
    const [addTimeLayout,setAddTimeLayout] = useState([])
    const [studentName, setStudentName] = useState("") 
    const [fatherName, setFatherName] = useState("")
    const [permanentAddress, setPermanentAddress] = useState("")
    const [presentAddress, setPresentAddress] = useState("")
    const [aadharNo, setAadharNo] = useState("")
    const [mobileNo, setMobileNo] = useState("")
    const [lockerNo, setLockerNo] = useState("")
    const [profileImg,setProfileImg] = useState("")
    const [aadharImg,setAadharImg] = useState("")
    const [profileImgDisplay,setProfileImgDisplay] = useState(profile)
    const [aadharImgDisplay,setAadharImgDisplay] = useState(aadhar)
    const [aadharImgRes,setAadharImgRes] = useState(false)
    const [startTime, setStartTime] = useState([])
    const [endTime, setEndTime] = useState([])
    const [examType,setExamType] = useState([])
    const [otherTypeExam,setOtherTypeExam] = useState("")
    const [mode,setMode] = useState("add");
    const [buttonType,setButtonType] = useState("Add");
    const[SnackBarMessage, setSnackBarMessage] = useState("")
    const[isSnackBarShow, setIsSnackBarShow] = useState(false)
    const [addMoreData,setAddMoreData] = useState([])
    const [seatNo,setSeatNo] = useState([null])
    const [dSeatNo,setDSeatNo] = useState([null])
    const[addEditLoading,setAddEditLoading] = useState(false)
    const[addMoreTimeCount,setAddMoreTimeCount] = useState(0)
    
    const mylabel = {color: "#5c00e6"};
    const border = {border: "2px solid #d1b3ff",padding: "30px",borderRadius:"10px"};
    const profileimg = {height: "150px",width: "auto"}
    const aadharimg = {height: "120px",width: "auto"}
    let studentImageRef = useRef()
    let studentaadharImageRef = useRef()
  
    const setExamTypeFun=(value)=>
    {
            let examType_arr = [...examType];
            if(examType_arr.includes(value))
            {
                examType_arr = examType_arr.filter((item)=>item!==value)
            }
            else
            {
                examType_arr.push(value)
            }
            setExamType(examType_arr);
    }

    const profileImagHandler = (e)=>{
        if(e.target.files[0])
        {
            setProfileImgDisplay(URL.createObjectURL(e.target.files[0]))
            setProfileImg(e.target.files[0]);
        }
    }

    const aadharImagHandler = (e)=>{
        if(e.target.files[0])
        {
            setAadharImgDisplay(URL.createObjectURL(e.target.files[0]))
            setAadharImg(e.target.files[0]);
        }
    }

    const fetchEditDetailsCallback=(response)=>{
        if(response.msg==="success"){
            setStudentName(response.data.name);
            setFatherName(response.data.f_name);
            setPermanentAddress(response.data.permanent_add);
            setPresentAddress(response.data.present_add);
            setAadharNo(response.data.aadhar_no);
            setMobileNo(response.data.mobile_no);
            setLockerNo(response.data.locker_no);
            setStartTime(response.data.start_time);
            setEndTime(response.data.end_time);
            setProfileImg(response.data.profile_file);
            setProfileImgDisplay(serverBaseUrl+response.data.profile_file);
            setAadharImgDisplay(serverBaseUrl+response.data.aadhar_file);
            if (response.data.aadhar_file) {
                setAadharImgRes(true);
            }
            setExamType(response.data.exam_type.split(","));
            setOtherTypeExam(response.data.other_exam);
            setSeatNo(response.data.s_no);
            setDSeatNo(response.data.d_sno);
            setAddMoreTimeCount(response.data.addMoreTimeCount - 1);
            setAddMoreData(response.data.addMoreData);
        }
    }

    useEffect(()=>{
        if(params.id)
        {   
            setMode("edit");
            //api which will fetch details of user with id params.id
            fetchEditDetails(params.id,fetchEditDetailsCallback);
            setButtonType("Save");
        }
    },[params.id])

    const addstudentCallback = (response) => {
        if (response.msg === "success") {
            setSnackBarMessage("Student Added Successfully")
            setIsSnackBarShow(true)
            history.push("/addRecipt/"+response.data.student_id);
        }else{
            setSnackBarMessage("Something went wrong")
            setIsSnackBarShow(true)
            history.push("/studentDetails/0");
        }
        setAddEditLoading(false)
    }

    const editstudentCallback = (response) => {
        if (response.msg === "success") {
            setSnackBarMessage("Saved  Successfully")
            setIsSnackBarShow(true)
            history.push("/studentDetails/0");
        }else{
            setSnackBarMessage("Something went wrong")
            setIsSnackBarShow(true)
            history.push("/studentDetails/0");
        }
        setAddEditLoading(false)
    }

    const addEditStudentHandler = ()=>{
        if(seatNo){
            if(mode==="add"){
                if(!addEditLoading)
                {
                    setAddEditLoading(true)
                    addStudent(adminDetails.s_no,studentName,fatherName,permanentAddress,presentAddress,aadharNo,mobileNo,startTime,endTime,lockerNo,profileImg,aadharImg,examType,otherTypeExam,seatNo,addstudentCallback)
                }
            }
            if(mode==="edit"){
                if(!addEditLoading)
                {
                    setAddEditLoading(true)
                    editStudent(params.id,adminDetails.s_no,studentName,fatherName,permanentAddress,presentAddress,aadharNo,mobileNo,startTime,endTime,lockerNo,profileImg,aadharImg,examType,otherTypeExam,seatNo,editstudentCallback)
                }
            }
        }
        else{
            setSnackBarMessage("Select the time slot first !!")
            setIsSnackBarShow(true)
        }  
    }

    const addMoreTimeSlotHandler = () => {
        let count = addMoreTimeCount + 1;
        setAddMoreTimeCount(count);
        setAddTimeLayout([...addTimeLayout,count])
        setViewTimeLayout(true)
    }

    const closeSnack=()=>{
        setIsSnackBarShow(false)
    }

    const updateSetSeatNo = (index,value) => {
        let tempSeatNo = seatNo;
        tempSeatNo[index] = value;
        setSeatNo(tempSeatNo);
    }

    return(

        <div className="wrapper container ">
            <div className="page-breadcrumb d-none d-md-flex align-items-center mb-3">
                <div className="breadcrumb-title pr-3">{buttonType} Student details</div>
                <div className="pl-3">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb mb-0 p-0">
                            <li className="breadcrumb-item">
                                <Link to={"/studentDetails/0"}>
                                    <i className='bx bx-home-alt'></i>
                                </Link>
                            </li>
                        </ol>
                    </nav>
                </div>
            </div>

            <form>
                <div className="container mt-5" style={border}>
                    <div className="row">
                        {mode==="edit"?(
                            <div className="col-3 form-floating mb-3 mt-2">
                             <h6><label htmlFor="studentNo">Student ID : MS-{params.id}</label></h6>
                            </div>
                        ):(null)}
                        
                        <div className="col">
                            <button className="float-right" type="button" onClick={()=>{studentImageRef.click()}} style={{backgroundColor:"transparent",border:"none"}}>
                                <img style={profileimg}  src={profileImgDisplay}  alt="chsssk"/>
                            </button>
                            <input type="file" ref={ref =>studentImageRef=ref} id="my_file" style={{visibility: "hidden"}} onChange={(e)=>profileImagHandler(e)}/>
                        </div>
                    </div>

                    <h5 className="mt-3">Exam Preparing for :</h5>
                    <div className="row">
                        <div className="col-2">
                            <div className="form-check mt-1">
                                <input name="exam_preparing" className="form-check-input" type="radio" value="1" id="flexCheckDefault1" checked={examType.includes("1")}   onChange={(e)=>setExamTypeFun(e.target.value)}></input>
                                <label className="form-check-label" htmlFor="flexCheckDefault1">I.Sc</label>
                            </div>
                        </div>
                        <div className="col-2">
                            <div className="form-check mt-1">
                                <input name="exam_preparing" className="form-check-input" type="radio" value="2" id="flexCheckDefault2" checked={examType.includes("2")}   onChange={(e)=>setExamTypeFun(e.target.value)}></input>
                                <label className="form-check-label" htmlFor="flexCheckDefault2">IIT / Medical</label>
                            </div>
                        </div>
                        <div className="col-2">
                            <div className="form-check mt-1">
                                <input name="exam_preparing" className="form-check-input" type="radio" value="3" id="flexCheckDefault3" checked={examType.includes("3")}   onChange={(e)=>setExamTypeFun(e.target.value)}></input>
                                <label className="form-check-label" htmlFor="flexCheckDefault3">Competition</label>
                            </div>
                        </div>
                        <div className="col-2">
                            <div className="form-check mt-1">
                                <input name="exam_preparing" className="form-check-input" type="radio" value="4" id="flexCheckDefault4" checked={examType.includes("4")}   onChange={(e)=>setExamTypeFun(e.target.value)}></input>
                                <label className="form-check-label" htmlFor="flexCheckDefault4">BPSC / UPSC</label>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="form-check">
                                <input type="text" className="form-control" value={otherTypeExam} onChange={e => setOtherTypeExam(e.target.value)} placeholder="Other" />
                            </div>
                        </div>
                    </div>
                
                    <div className="row mt-4" style={mylabel}>
                        <div className="col-6 form-floating mb-3 mt-2">
                            <b><label htmlFor="studentName">Student Name</label></b>
                            <input type="text" className="form-control" id="studentName" value={studentName} onChange={e => setStudentName(e.target.value)} placeholder="Student Name" />
                        </div>
                        <div className="col-6 form-floating mb-3 mt-2">
                            <b><label htmlFor="studentName">Father's Name</label></b>
                            <input type="text" className="form-control" id="studentName" value={fatherName} onChange={e => setFatherName(e.target.value)} placeholder="Father's Name" />
                        </div>
                    </div>

                    <div className="row" style={mylabel}>
                        <div className="col form-floating mb-3 mt-2">
                            <b><label htmlFor="permanentAddress">Permanent Address</label></b>
                            <textarea className="form-control" placeholder="Permanent address" value={permanentAddress} id="permanentAddress" onChange={e => setPermanentAddress(e.target.value)}></textarea>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col">
                            <h6 className="float-right"><input type="checkbox" onChange={e => (e.target.checked) ? setPresentAddress(permanentAddress) : ""} />&nbsp;&nbsp;<label>Same as Permanent Address</label></h6>
                        </div>
                    </div>

                    <div className="row" style={mylabel}>
                        <div className="col form-floating mb-3 mt-2">
                            <b><label htmlFor="presentAddress">Present Address</label></b>
                            <textarea className="form-control" placeholder="Present address" value={presentAddress} id="presentAddress" onChange={e => setPresentAddress(e.target.value)}></textarea>
                        </div>
                    </div>

                    <div className="row" style={mylabel}>
                        <div className="col form-floating mb-3 mt-2">
                            <b><label htmlFor="aaadharNo">Aadhar No.</label></b>
                            <input type="number" className="form-control" value={aadharNo} onChange={e => setAadharNo(e.target.value)} placeholder="Aadhar No" id="aaadharNo"/>
                        </div>
                        <div className="col form-floating mb-3 mt-2">
                            <b><label htmlFor="mobileNo">Mobile No.</label></b>
                            <input type="number" className="form-control" value={mobileNo} onChange={e => setMobileNo(e.target.value)} placeholder="Mobile No" id="mobileNo"/>
                        </div>
                    </div>
                    <div className="row mt-4">
                    </div>
                    <div className="row mt-2">
                        <div className="col-5 form-floating mb-3 " style={mylabel}>
                            <b><label htmlFor="mobileNo">Locker No.</label></b>
                            <input type="number" className="form-control" value={lockerNo} onChange={e => setLockerNo(e.target.value)} placeholder="Locker No" id="mobileNo"/>
                        </div>
                        <div className="col-5 form-floating mb-3 " style={mylabel}>
                            <b><label htmlFor="mobileNo">Aadhar Card :</label></b>
                            <a className="form-control" type="button" onClick={()=>{studentaadharImageRef.click()}} style={{backgroundColor:"transparent",border:"none"}}>
                                <img style={aadharimg}  src={aadharImgDisplay}  alt="chsssk"/>
                            </a>
                            <input type="file" ref={ref =>studentaadharImageRef=ref} id="my_file" style={{visibility: "hidden"}} onChange={(e)=>aadharImagHandler(e)}/>
                        </div>
                        {mode==="edit" && aadharImgRes?(
                            <div className="col-2 form-floating mb-3" style={mylabel}>
                                <a className="btn btn-success" rel="noreferrer" href={aadharImgDisplay} target="_blank">Click to View</a>
                            </div>
                        ) : "" }
                    </div>
                    <div className="row mt-4">
                        <div className="col mt-4">
                            <button onClick={addMoreTimeSlotHandler} className="btn btn-primary float-right" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                Add Time
                            </button>
                        </div>
                    </div>

                    {
                        addMoreData[0] ?
                            addMoreData.map((row,j)=>(
                                <RenderSingleTime index={j} startTime={startTime}  endTime={endTime} mylabel={mylabel} mode={mode} data={row} updateSetSeatNo={updateSetSeatNo}/>
                            )) : <RenderSingleTime index={0} startTime={startTime}  endTime={endTime} mylabel={mylabel} mode={mode} data={[]} updateSetSeatNo={updateSetSeatNo}/>
                    }

                    {
                        (viewTimeLayout) ?
                        addTimeLayout.map((i,j) => (
                            <RenderSingleTime index={i} startTime={startTime}  endTime={endTime} mylabel={mylabel} mode={mode} data={[]} updateSetSeatNo={updateSetSeatNo}/>
                        )) : ''
                    }
                        
                    <div className="btn-group mt-5 w-100">
                        <button type="button" onClick={addEditStudentHandler} className="btn btn-primary btn-block" >
                            {addEditLoading?(
                                <div className="spinner-border text-light" role="status">	<span className="sr-only">Loading...</span>
                                </div>
                            ):(
                                <span>{buttonType} Student</span>
                            )}
                        </button>
                    </div>

                </div>
            </form>

            <Snackbar
                open={isSnackBarShow}
                onClose={(e)=>closeSnack(e)}
                TransitionComponent="TransitionUp"
                message={SnackBarMessage}
            />
        </div>
    )
}
